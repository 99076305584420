import './CheckoutPage.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble, faShoppingBasket, faSquare, faCheckSquare, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import request from '../request';
import basket from '../basket';
import discountBasket from '../discountBasket';
import Select from 'react-select';
import storage from '../storage';
import mathUtils from '../mathUtils';
import loadingOrder from '../assets/img/loading_order2.gif';
import loadingImage from '../assets/img/loading.gif';
import history from '../history';
import gtag_evt from '../gtag';

class CheckoutPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shipping_choices_loaded: true,
      products: [],
      discount_products: [],
      invalidProducts: [],
      deliveryPointTypeOptions: [],
      deliveryPointType: 1,
      orderSent: false,
      completed_order_id: null,
      user_lookups: null,
      shipping_choices: null,
      sending_order: false,
      error_initial: false,
      error_form: {
        wholename: false,
        company_delivery_name: false,
        email: false,
        phone: false,
        address: false,
        buildingFloor: false,
        city: false,
        zip: false,
        notes: false,
        paymentMethod: false,
        shippingMethod: false,
        shippingUserMethod: false,
        userShippingName: false,
        userShippingAddress: false,
        userShippingPhone: false,
        userShippingCounty: false,
      },
      form: {
        wholename: '',
        company_delivery_name: '',
        email: '',
        phone: '',
        address: '',
        buildingFloor: '',
        city: '',
        zip: '',
        notes: '',
        paymentMethod: null,
        shippingMethod: null,
        shippingUserMethod: null,
        userShippingName: '',
        userShippingAddress: '',
        userShippingPhone: '',
        userShippingCounty: '',
        activeCoupon: null
      },
      valid_user_data: true,
      same_address: true,
      save_address: false,
      
    };

    this.send = this.send.bind(this);
    this.onFormInputChange = this.onFormInputChange.bind(this);
    this.calculatePrice = this.calculatePrice.bind(this);
  }

  componentDidMount() {
    document.title = i18n.t('checkout_page_title');
    if (storage.me.id) {
      this.state.form.firstname = storage.me.firstname;
      this.state.form.lastname = storage.me.lastname;
      this.state.form.email = storage.me.email;
      this.state.form.phone = storage.me.phone;
    }else{
      history.push('/')
    }

    storage.onChange((property) => {
      if (property === 'me') {
        this.state.form.firstname = storage.me.firstname;
        this.state.form.lastname = storage.me.lastname;
        this.state.form.email = storage.me.email;
        this.state.form.phone = storage.me.phone;
      }
    });

    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });

    request.get(`api/checkout_lookups`).then((response) => {
      if (response.data) {
        this.setState({
          ...this.state,
          user_lookups: response.data.data
        }, () => {
          this.validateUserLookups();
          this.setSameAddress()
        });
      }
    });
    // basket.validateBasketStorage()
    discountBasket.validateBasketStorage()

    this.basketUnmount = basket.onChange(() => {
      this.setState({
        ...this.state,
        products: basket.products,
        discount_products: discountBasket.products,
        invalidProducts: basket.invalidProducts
      }, () => {
        if (this.state.products.length > 0) {
          
          gtag_evt.gtag('event', 'begin_checkout', {
            currency: 'EUR',
            items: this.gtagPrepareItems(),
            value: this.calcSubtotal()
          });
        }
      });
      this.forceUpdate();
    });

    this.discountBasketUnmount = discountBasket.onChange(() => {
      this.setState({
        ...this.state,
        products: basket.products,
        discount_products: discountBasket.products,
      });
      this.forceUpdate();
    });

    this.setState({
      ...this.state,
      products: basket.products,
      discount_products: discountBasket.products,
      invalidProducts: basket.invalidProducts
    });

    request.get(`api/order-address-type`).then((response) => {
      if (response.data) {
        this.setState({
          ...this.state,
          deliveryPointTypeOptions: response.data.map((option) => {
            return {
              label: option.name,
              value: option.id,
            };
          }),
        });
      }
    });
  }

  gtagPrepareItems() {
    var items = this.state.products.map(p => {
      return {
        item_id: p.code,
        item_name: p.name,
        item_brand: p.manufacturer,
        item_category: null,
        price: this.calculatePrice(p),
        currency: 'EUR'
      }
    });

    var discount_items = this.state.discount_products.map(p => {
      return {
        item_id: p.code,
        item_name: p.name,
        item_brand: p.manufacturer,
        item_category: null,
        price: this.calculateWholeDiscountPrice(p),
        currency: 'EUR'
      }
    });
    
    return items.concat(discount_items)
  }

  validateUserLookups() {
    const errors = {};

    const payment_address = this.state.user_lookups.addresses.filter(a => a.id == this.state.user_lookups.main_address)[0]

    errors.activity_code = (this.state.user_lookups.activity_code == '' || this.state.user_lookups.activity_code == null)
    errors.company_name = (this.state.user_lookups.company_name == '' || this.state.user_lookups.company_name == null)
    errors.doy = (this.state.user_lookups.doy == '' || this.state.user_lookups.doy == null) && payment_address.country == 'ΕΛΛΑΣ'
    errors.company_name = (this.state.user_lookups.company_name == '' || this.state.user_lookups.company_name == null)
    errors.email = (this.state.user_lookups.email == '' || this.state.user_lookups.email == null)
    errors.tax_registration_number = (this.state.user_lookups.tax_registration_number == '' || this.state.user_lookups.tax_registration_number == null)

    errors.address = (payment_address.address == '' || payment_address.address == null)
    errors.city = (payment_address.city == '' || payment_address.city == null)
    errors.country = (payment_address.country == '' || payment_address.country == null)
    errors.zip = (payment_address.zip == '' || payment_address.zip == null)
    errors.region = (payment_address.region == '' || payment_address.region == null)  && payment_address.country == 'ΕΛΛΑΣ'
    
    let valid = true;
    Object.entries(errors).map(item => {
      if(item[1]) valid = false;
    })
    
    this.setState({
      ...this.state,
      valid_user_data: valid,
    })
    return valid;
  }
  componentWillUnmount() {
    this.unlistenOnLanguageChange();
    this.basketUnmount();
    this.discountBasketUnmount();
  }
  componentDidUpdate(prevProps, prevState) {
    
    document.title = i18n.t('checkout_page_title');
    if (prevState.products.length !== this.state.products.length) {
      const getThumbnailsPromises = [];
      const p = this.state.products.slice();
      for (let i = 0; i < p.length; i++) {
        const thumbnail = new Image();
        getThumbnailsPromises.push(new Promise((resolve, reject) => {
          thumbnail.onload = () => {
            resolve(thumbnail);
          };

          thumbnail.onerror = ((i) => {
            return () => {
              p[i].thumbnail = '/no-product-image-image.png';
              resolve(thumbnail);
            };
          })(i);
        }));

        thumbnail.src = p[i].thumbnail;
      }

      Promise.all(getThumbnailsPromises).then((thumbnails) => {
        this.setState({
          ...this.state,
          products: p,
        });
      });

      
    }
    if(this.state.same_address && !prevState.same_address){
      this.setSameAddress()
    }
  }
  validateForm() {
    

    const errors = {};

    errors.wholename = (this.state.form.wholename == '' || this.state.form.wholename == null) && this.state.same_address == false;
    // errors.company_delivery_name = (this.state.form.company_delivery_name == '' || this.state.form.company_delivery_name == null) && this.state.same_address == false;
    // errors.email = this.state.form.email == '' || this.state.form.email == null;
    errors.phone = this.state.form.phone == '' || this.state.form.phone == null;
    errors.address = this.state.form.address == '' || this.state.form.address == null;
    errors.city = this.state.form.city == '' || this.state.form.city == null;
    errors.region = (this.state.form.region == '' || this.state.form.region == null) && (this.state.form.country == 'ΕΛΛΑΣ');
    errors.country = this.state.form.country == '' || this.state.form.country == null;
    errors.zip = this.state.form.zip == '' || this.state.form.zip == null;
    errors.paymentMethod = this.state.form.paymentMethod == '' || this.state.form.paymentMethod == null;
    errors.shippingMethod = this.state.form.shippingMethod == '' || this.state.form.shippingMethod == null;
    errors.shippingUserMethod = this.state.shipping_choices != null 
                && !errors.shippingMethod 
                && this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod).length > 0 
                && this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod)[0].user_choices.length > 0 
                && (this.state.form.shippingUserMethod == '' || this.state.form.shippingUserMethod == null);
    /*
    userShippingName: '',
    userShippingAddress: '',
    userShippingPhone: '',
    userShippingCounty: '',
    */
    errors.userShippingName = this.state.shipping_choices != null 
        && !errors.shippingMethod 
        && this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod).length > 0 
        && this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod)[0].manual_input == true 
        && (this.state.form.userShippingName == '' || this.state.form.userShippingName == null)
    errors.userShippingAddress = this.state.shipping_choices != null 
        && !errors.shippingMethod 
        && this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod).length > 0 
        && this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod)[0].manual_input == true 
        && (this.state.form.userShippingAddress == '' || this.state.form.userShippingAddress == null)
    errors.userShippingPhone = this.state.shipping_choices != null 
        && !errors.shippingMethod 
        && this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod).length > 0 
        && this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod)[0].manual_input == true 
        && (this.state.form.userShippingPhone == '' || this.state.form.userShippingPhone == null)
    errors.userShippingCounty = this.state.shipping_choices != null 
        && !errors.shippingMethod 
        && this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod).length > 0 
        && this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod)[0].manual_input == true 
        && (this.state.form.userShippingCounty == '' || this.state.form.userShippingCounty == null)
    
    var valid = true;

    Object.entries(errors).map(item => {
      if(item[1]) valid = false;
    })
    
    this.setState({
      ...this.state,
      error_form: errors
    })
    return valid;

  }
  send() {
    if(this.state.sending_order) return;

    

    if(!this.validateForm()){
      return;
    }
    
    this.setState({
      ...this.state,
      sending_order: true
    })
    /*
    shippingUserMethod: null,
        userShippingName: '',
        userShippingAddress: '',
        userShippingPhone: '',
        userShippingCounty: '',
    */
    const clientData = {
      wholename: this.state.form.wholename,
      company_delivery_name: this.state.form.company_delivery_name,
      email: this.state.form.email,
      phone: this.state.form.phone,
      shippingAddress: {
        address: this.state.form.address,
        city: this.state.form.city,
        zip: this.state.form.zip,
        region: this.state.form.region,
        country: this.state.form.country,
        phone: this.state.form.phone,
        save_address: this.state.save_address && !this.state.same_address ? 1 : 0
      },
      notes: this.state.form.notes,
      paymentMethod: this.state.form.paymentMethod,
      shippingMethod: this.state.form.shippingMethod,
      shippingUserMethod: this.state.form.shippingUserMethod,
      userShippingName: this.state.form.userShippingName,
      userShippingAddress: this.state.form.userShippingAddress,
      userShippingPhone: this.state.form.userShippingPhone,
      userShippingCounty: this.state.form.userShippingCounty,
      activeCoupon: this.state.form.activeCoupon
    };

    const productsData = this.state.products.map((p) => {
      return {
        id: p.id,
        quantity: p.quantity,
        variant: p.variant_id
      };
    });

    const discountProductsData = this.state.discount_products.map((p) => {
      return {
        id: p.id,
        quantity: p.quantity,
        variant: p.variant_id,
        discount: p.discount
      };
    });
    
    request.post(`api/add-order`, {
      client: clientData,
      products: productsData,
      discount_products: discountProductsData
    }).then((response) => {
      this.setState({
        ...this.state,
        sending_order: false
      })
      if (!('status' in response.data)) {
        console.warn('Μη ολοκληρωμένη απάντηση από το server για την ολοκλήρωση της παραγγελίας');
        return;
      }

      if (response.data.status === 'ok') {
        const [shipping, payment, shipping_tax, payment_tax, flat_discount] = this.getShipping()
        gtag_evt.gtag('event', 'purchase', {
          currency: 'EUR',
          items: this.gtagPrepareItems(),
          value: this.getGrandTotal(),
          transaction_id: response.data.order_id,
          shipping: (shipping_tax + payment_tax),
          tax: 0
        });
        
        this.setState({
          ...this.state,
          orderSent: true,
          completed_order_id: response.data.order_id,
          form: {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            zip: '',
            notes: '',
            paymentMethod: null,
            shippingMethod: null
          },
        });

        basket.clear();
        discountBasket.clear();
        window.scrollTo(0, 0);
      }
    });
  }
  getShippingChoices(){
    if(this.state.form.country && (this.state.form.region || this.state.form.country !== 'ΕΛΛΑΣ')) {
      this.setState({
        ...this.state,
        shipping_choices_loaded: false
      })
      request.post(`api/checkout_shipping_choices`, {
        country: this.state.form.country,
        region: this.state.form.region,
        zip: this.state.form.zip,
        basket: this.state.products,
        discount_basket: this.state.discount_products,
        p: this.getPrices()
      }).then((response) => {
        if(!request.response_valid(response)){
          return;
        }
        
        let shipping = Object.values(response.data.data).map((s) => {
          s.payment = Object.values(s.payment)
          return s
        })

        this.setState({
          ...this.state,
          shipping_choices: shipping,
          shipping_choices_loaded: true
        }, () => {
          clearTimeout(this.shipping_loaded_timeout)
        })
      })
    }

    this.shipping_loaded_timeout = setTimeout(() => {
      this.setState({
        ...this.state,
        shipping_choices_loaded: true
      })
    }, 10000)
    
  }
  onFormInputChange(e, inputName) {
    const variableName = inputName.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
    let fn;
    if(variableName == 'region' || variableName == 'country' || variableName == 'zip'){
      fn = () => {
        this.getShippingChoices()
      }
    }else{
      fn = () => {}
    }
    if(e.target){
      this.setState({
        ...this.state,
        error_form: {
          ...this.state.error_form,
          [variableName]: false,
        },
        form: {
          ...this.state.form,
          [variableName]: e.target.value,
        },
      }, () => fn());
    }else{
      this.setState({
        ...this.state,
        error_form: {
          ...this.state.error_form,
          [variableName]: false,
        },
        form: {
          ...this.state.form,
          [variableName]: e.value,
        },
      }, () => fn());
    }
    
  }

  removeProduct(product) {
    basket.removeProduct(product, product.variant_id);
  }

  calculatePrice(product) {
    let price = 0;
    let total_qty = this.state.products.map((prod) => {
      return prod.id == product.id ? prod.quantity : 0;
    }).reduce((p, c) => {
      return p + c;
    }, 0);
    let valid_prices = product.prices.filter( (p) => {
      return p.qty <= total_qty
    })
    if(valid_prices.length > 0){
      let single_price = valid_prices.reduce(function (p, v) {
        return ( p.qty > v.qty ? p : v );
      });
      return parseFloat(single_price.price).toFixed(2);
    }else{
      return 0;
    }
    
  }

  getPicking(product){
    return product.picking_charge
  }

  calculateTotalProductPrice(product){
    
    return ( parseFloat(this.calculatePrice(product) * product.quantity) + parseFloat(this.getPicking(product)) ).toFixed(2)
  }

  calculatePickingPrice(){
    
    let price = 0;
    this.state.products.map( (product) => {
      if(product.picking_package && product.picking_package > 0 && product.picking_price && product.picking_price > 0 && product.quantity < 100){
      
        price += parseFloat(this.getPicking(product))
          
      }
    } )
    
    return price.toFixed(2)
  }
  calculateDiscountPrice(product) {
    let price = product.price - ( (product.discount.percentage / 100) * product.price );
    
    return parseFloat(price).toFixed(2);
  }

  calculateWholeDiscountPrice(product) {
    return ( mathUtils.round(( (product.price * product.quantity) - ( (product.discount.percentage / 100) * (product.price * product.quantity) ) )) )
  }

  getSubtotal(subtotal, subtotal_discount) {
    return (parseFloat(subtotal + subtotal_discount).toFixed(2))
  }

  onAddressChange(e) {
    let temp_form = this.state.form
    let address = this.state.user_lookups.addresses.filter(a => a.id == e.value)[0]

    let form = {
      wholename: '',
      email: '',
      phone: address.phone,
      address: address.address,
      city: address.city,
      region: address.region,
      country: address.country,
      zip: address.zip,
      notes: address.notes,
      paymentMethod: null,
      shippingMethod: null,
    }
    this.setState({
      ...this.state,
      selected_address: e.value,
      form: form
    }, () => this.getShippingChoices())
  }

  setSameAddress() {
    this.onAddressChange({value: this.state.user_lookups.main_address})
  }
  calcSubtotal(){
    var prods = [];
    if(this.state.products == null || this.state.products.length == 0) return 0;
    this.state.products.map(p => {
      var qty = 0;
      var price = this.calculatePrice(p);
      if(typeof prods[p.id] != 'undefined'){
        qty = prods[p.id].qty;
      }
      qty += p.quantity;
      prods[p.id] = {
        qty: qty,
        price: price
      }
    })
    var total = 0;
    var prices = prods.map(p => p.price * p.qty);
    if(prices.length > 0){
      total = prices.reduce((a, b) => a + b);
    }
    return mathUtils.round(total);
  }

  getGrandTotal() {
    const vat = storage.me.vat ? (mathUtils.round(storage.me.vat) / 100) : 0.24;
    var prods = [];
    if(this.state.products == null || this.state.products.length == 0) return 0;
    this.state.products.map(p => {
      var qty = 0;
      var price = this.calculatePrice(p);
      var picking = 0;
      if(typeof prods[p.id] != 'undefined'){
        qty = prods[p.id].qty;
        picking = prods[p.id].picking;
      }
      qty += p.quantity;
      picking += mathUtils.round(this.getPicking(p));
      prods[p.id] = {
        qty: qty,
        price: price,
        picking: picking
      }
    })
    var total = 0;
    var prices = prods.map(p => mathUtils.round((p.price * p.qty + p.picking) * (vat + 1)));
    if(prices.length > 0){
      total = prices.reduce((a, b) => a + b);
    }
    return mathUtils.round(total);
  }

  getDiscountSubtotal(){
    var prods = [];
    if(this.state.discount_products == null || this.state.discount_products.length == 0) return 0;
    this.state.discount_products.map(p => {
      var qty = 0;
      var price = p.price;
      if(typeof prods[`line${p.id}_${p.discount.id}`] != 'undefined'){
        qty = prods[`line${p.id}_${p.discount.id}`].qty;
      }
      qty += p.quantity;
      prods[`line${p.id}_${p.discount.id}`] = {
        qty: qty,
        price: price,
      }
    })
    
    var total = 0;
    var index_array = [];
    for (var key in prods) {
      index_array.push({
        qty: prods[key].qty,
        price: prods[key].price,
      })
    }
    var prices = index_array.map(p1 => {
      return p1.price * p1.qty;
    });
    if(prices.length > 0){
      total = prices.reduce((a, b) => a + b, 0);
    }
    return mathUtils.round(total);
  }
  getShipping() {
    const vat = storage.me.vat ? mathUtils.round(storage.me.vat) / 100 : 0.24;
    var shipping;
    var payment;
    var shipping_tax;
    var payment_tax;
    if(this.state.shipping_choices){
      shipping = this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod).length > 0 ? mathUtils.round(this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod)[0].charge) : 0;
      payment = this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod).length > 0
      && this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod)[0].payment.filter(p => p.type == this.state.form.paymentMethod).length > 0
      ? mathUtils.round(this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod)[0].payment.filter(p => p.type == this.state.form.paymentMethod)[0].charge) : 0;

      shipping_tax = mathUtils.round(shipping * vat);
      payment_tax = mathUtils.round(payment * vat);
      
    }else{
      shipping = 0;
      payment = 0;
      shipping_tax = 0;
      payment_tax = 0;
    }

    if(isNaN(shipping)){
      shipping = 0;
    }
    if(isNaN(payment)){
      payment = 0;
    }
    if(isNaN(shipping_tax)){
      shipping_tax = 0;
    }
    if(isNaN(payment_tax)){
      payment_tax = 0;
    }

    return [
      shipping,
      payment,
      shipping_tax,
      payment_tax,
    ]
  }
  getDiscountPrice(vat){
    
    var prods = [];
    if(this.state.discount_products == null || this.state.discount_products.length == 0) return 0;
    this.state.discount_products.map(p => {
      var qty = 0;
      var price = p.price;
      if(typeof prods[`${p.id}-${p.discount.id}`] != 'undefined'){
        qty = prods[`${p.id}-${p.discount.id}`].qty;
      }
      qty += p.quantity;
      prods[`${p.id}-${p.discount.id}`] = {
        qty: qty,
        price: price,
        discount: p.discount.percentage
      }
    })
    //console.log(prods)
    var total = 0;
    var index_array = [];
    for (var key in prods) {
      index_array.push({
        qty: prods[key].qty,
        price: prods[key].price,
        discount: prods[key].discount,
      })
    }
    var prices = index_array.map(p => mathUtils.round( ((p.price * p.qty) - ((p.price * p.qty) * (p.discount / 100))) * (vat + 1), 3) );
    if(prices.length > 0){
      total = prices.reduce((a, b) => a + b);
    }
    return mathUtils.round(total);
  }
  getPrices() {
    const subtotal = this.calcSubtotal()
    const subtotal_after_discount_clear = this.getDiscountPrice(0)
    const picking = mathUtils.round(this.calculatePickingPrice());

    return mathUtils.round(subtotal + subtotal_after_discount_clear + picking);
  }

  checkDiscounts() {
    let clear = true;

    if(this.state.discount_products.length > 0) {
      clear = false;
    }

    if(this.state.products.findIndex(p => typeof p.prices[0].discount !== "undefined") > -1){
      clear = false;
    }

    return clear;
  }

  filterCoupons(coupons) {
    const subtotal = this.calcSubtotal()
    return coupons.filter(c => c.discount_price <= subtotal)
  }

  render() {
    const vat = storage.me.vat ? mathUtils.round(storage.me.vat) / 100 : 0.24;
    const subtotal = this.calcSubtotal()
    const subtotal_after_discount = this.getDiscountPrice(vat)
    const subtotal_after_discount_clear = this.getDiscountPrice(0)
    const subtotal_discount = this.getDiscountSubtotal()

    
    const [shipping, payment, shipping_tax, payment_tax] = this.getShipping()
    const whole_charge = mathUtils.round(shipping + payment + shipping_tax + payment_tax);


    const discount = subtotal * (this.state.discountPerc / 100);
    
    //const grandTotal = subtotal + taxes;
    const picking = mathUtils.round(this.calculatePickingPrice());
    const activeCouponDiscount = this.state.form.activeCoupon ? parseFloat(this.state.form.activeCoupon.discount_price) : 0;
    const activeCouponDiscountVat = (activeCouponDiscount  * (vat + 1));
    const grandTotal = activeCouponDiscountVat < this.getGrandTotal() ? this.getGrandTotal() - (activeCouponDiscount  * (vat + 1)) : 0;
    
    
    const grandTotal_discount = subtotal_after_discount

    //const taxes =  mathUtils.round(grandTotal - (subtotal + whole_charge + picking));
    const taxes =  ((grandTotal + grandTotal_discount + whole_charge + activeCouponDiscount) - (subtotal + shipping + payment + subtotal_after_discount_clear + picking) );

    
    return (
      this.state.valid_user_data ?
      <div className={`checkout-page ${this.state.products.length === 0 && localStorage.basket === '{}' ? 'empty-height' : ''}`}>
        {
          this.state.sending_order &&
          <div className='loading-container'>
            <img src={loadingOrder}/>
            <span>{i18n.t("sending_order")}</span>
          </div>
        }
        {
          this.state.orderSent === false && this.state.products.length === 0 && localStorage.basket !== '{}'
            ? <div className="skeletons">
                <div className="skeletons__form"></div>
                <div className="skeletons__basket"></div>
              </div>
            : ''
        }
        {
          this.state.orderSent === false && this.state.products.length === 0 && localStorage.basket === '{}'
            ? <div className="d-column-center w-100 h-100">
                <FontAwesomeIcon icon={faShoppingBasket} size="3x" />
                <span className="mt-2">{i18n.t('empty_basket')}</span>
              </div>
            : ''
        }
        <div className={`shipping-details ${this.state.products.length === 0 || this.state.orderSent ? 'hidden' : ''}`}>
          <div className="header">
            {i18n.t('invoice_details')}
          </div>
          {
            this.state.user_lookups &&
            <div className="body">
              <div className="input input-3">
                <label htmlFor="company_name">company_name</label>
                <span>{i18n.t('company_name')}</span>
                <input
                  id="company_name"
                  type="text"
                  value={this.state.user_lookups.company_name}
                  readOnly
                ></input>
              </div>
              <div className="input input-3">
                <label htmlFor="vat">vat</label>
                <span>{i18n.t('vat')}</span>
                <input
                  id="vat"
                  type="text"
                  value={this.state.user_lookups.tax_registration_number}
                  readOnly
                ></input>
              </div>
              <div className="input input-3">
                <label htmlFor="tax_office">tax_office</label>
                <span>{i18n.t('tax_office')}</span>
                <input
                  id="tax_office"
                  type="text"
                  value={this.state.user_lookups.doy_name ? this.state.user_lookups.doy_name : ''}
                  readOnly
                ></input>
              </div>
              <div className="input input-3">
                <label htmlFor="profession">profession</label>
                <span>{i18n.t('profession')}</span>
                <input
                  id="profession"
                  type="text"
                  value={this.state.user_lookups.activity_code}
                  readOnly
                ></input>
              </div>
              <div className="input input-3">
                <label htmlFor="phone">phone</label>
                <span>{i18n.t('phone')}</span>
                <input
                  id="phone"
                  type="text"
                  value={this.state.user_lookups.addresses.filter(a => a.id == this.state.user_lookups.main_address)[0].phone}
                  readOnly
                ></input>
              </div>
              <div className="input input-3">
                <label htmlFor="address">address</label>
                <span>{i18n.t('address')}</span>
                <input
                  id="address"
                  type="text"
                  value={this.state.user_lookups.addresses.filter(a => a.id == this.state.user_lookups.main_address)[0].address}
                  readOnly
                ></input>
              </div>
              <div className="input input-3">
                <label htmlFor="city">city</label>
                <span>{i18n.t('city')}</span>
                <input
                  id="city"
                  type="text"
                  value={this.state.user_lookups.addresses.filter(a => a.id == this.state.user_lookups.main_address)[0].city}
                  readOnly
                ></input>
              </div>
              <div className="input input-3">
                <label htmlFor="zip">zip</label>
                <span>{i18n.t('zip')}</span>
                <input
                  id="zip"
                  type="text"
                  value={this.state.user_lookups.addresses.filter(a => a.id == this.state.user_lookups.main_address)[0].zip}
                  readOnly
                ></input>
              </div>
              <div className="input input-3">
              <label htmlFor="email">email</label>
              <span>{i18n.t('email')}</span>
              <input
                id="email"
                type="text"
                value={this.state.user_lookups.email}
                readOnly
              ></input>
            </div>
            </div>
          }
          
          <div className="header">
            {i18n.t('shipping_address')}
          </div>
          <div className="body">
            <div style={{fontSize: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '7px'}}>
              {
                /**
                   font-size: 20px;
  display: inline-block;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 7px;
}
                 */
                this.state.same_address
                  ? <FontAwesomeIcon icon={faCheckSquare}
                      onClick={() => this.setState({ ...this.state, same_address: !this.state.same_address })}
                    />
                  : <FontAwesomeIcon
                      icon={faSquare}
                      onClick={() => this.setState({ ...this.state, same_address: !this.state.same_address })}
                    />
              }
              &nbsp;
              <span
                onClick={() => this.setState({ ...this.state, same_address: !this.state.same_address })}
                style={{cursor: "pointer"}}
              >
                {
                  i18n.t("same_address_as_billing")
                }
              </span>
            </div>
          </div>
          {
            !this.state.same_address && 
            <div className="body">
            
            {
              this.state.user_lookups &&
              <div className="input input-full">
                <label htmlFor="choose_address">choose_address</label>
                <span>{i18n.t('choose_address')}</span>
                
                <Select
                  id="choose_address"
                  type="text"
                  options={
                    this.state.user_lookups.addresses.map(a => {
                      return {
                        label: `${a.address} ${a.city}, ${a.zip}`,
                        value: a.id
                      }
                    })
                  }
                  value={this.state.selected_address ? this.state.user_lookups.addresses.filter(a => a.id == this.state.selected_address).map(a => {
                    return {
                      label: `${a.address} ${a.city}, ${a.zip}`,
                      value: a.id
                    }
                  }) : ''}
                  onChange={(e) => this.onAddressChange(e)}
                />
              </div>
            }
            
            <div className="input input-whole">
              <label htmlFor="wholename">wholename</label>
              <span>{i18n.t('wholename')}</span>
              <span className={`error-form ${this.state.error_form.wholename ? 'show' : ''}`}>{i18n.t('mandatory_field')}</span>
              <input
                id="wholename"
                type="text"
                value={this.state.form.wholename}
                onChange={(e) => this.onFormInputChange(e, 'wholename')}
              ></input>
            </div>
            <div className="input input-whole">
              <label htmlFor="company_delivery_name">company_delivery_name</label>
              <span>{i18n.t('company_delivery_name_optional')}</span>
              <input
                id="company_delivery_name"
                type="text"
                value={this.state.form.company_delivery_name}
                onChange={(e) => this.onFormInputChange(e, 'company_delivery_name')}
              ></input>
            </div>
            
            <div className="input input-whole">
              <label htmlFor="phone">phone</label>
              <span>{i18n.t('phone')}</span>
              <span className={`error-form ${this.state.error_form.phone ? 'show' : ''}`}>{i18n.t('mandatory_field')}</span>
              <input
                id="phone"
                type="text"
                value={this.state.form.phone}
                onChange={(e) => this.onFormInputChange(e, 'phone')}
              ></input>
            </div>
            <div className="input input-whole">
              <label htmlFor="address">address</label>
              <span>{i18n.t('address')}</span>
              <span className={`error-form ${this.state.error_form.address ? 'show' : ''}`}>{i18n.t('mandatory_field')}</span>
              <input
                id="address"
                type="text"
                value={this.state.form.address}
                onChange={(e) => this.onFormInputChange(e, 'address')}
              ></input>
            </div>
            
            <div className="input input-whole">
              <label htmlFor="city">city</label>
              <span>{i18n.t('city')}</span>
              <span className={`error-form ${this.state.error_form.city ? 'show' : ''}`}>{i18n.t('mandatory_field')}</span>
              <input
                id="city"
                type="text"
                value={this.state.form.city}
                onChange={(e) => this.onFormInputChange(e, 'city')}
              ></input>
            </div>
            <div className="input input-whole">
              <label htmlFor="zip">zip</label>
              <span>{i18n.t('zip')}</span>
              <span className={`error-form ${this.state.error_form.zip ? 'show' : ''}`}>{i18n.t('mandatory_field')}</span>
              <input
                id="zip"
                type="text"
                value={this.state.form.zip}
                onChange={(e) => this.onFormInputChange(e, 'zip')}
              ></input>
            </div>
            {
              this.state.user_lookups &&
              <div className="input input-whole">
                <label htmlFor="country">country</label>
                <span>{i18n.t('country')}</span>
                <span className={`error-form ${this.state.error_form.country ? 'show' : ''}`}>{i18n.t('mandatory_field')}</span>
                <Select
                    id="country"
                    type="text"
                    options={
                      this.state.user_lookups.lookups.countries.map(a => {
                        return {
                          label: `${a.name}`,
                          value: a.id
                        }
                      })
                    }
                    value={this.state.form.country ? this.state.user_lookups.lookups.countries.filter(a => a.id == this.state.form.country).map(a => {
                      return {
                        label: `${a.name}`,
                          value: a.id
                      }
                    }) : ''}
                    onChange={(e) => this.onFormInputChange(e, 'country')}
                  />
              </div>
            }
            {
              this.state.user_lookups && this.state.form.country == 'ΕΛΛΑΣ' ?
              <div className="input input-whole">
                <label htmlFor="region">region</label>
                <span>{i18n.t('region')}</span>
                <span className={`error-form ${this.state.error_form.region ? 'show' : ''}`}>{i18n.t('mandatory_field')}</span>
                <Select
                    id="region"
                    type="text"
                    options={
                      this.state.user_lookups.lookups.regions.map(a => {
                        return {
                          label: `${a.description}`,
                          value: a.id
                        }
                      })
                    }
                    value={this.state.form.region ? this.state.user_lookups.lookups.regions.filter(a => a.id == this.state.form.region).map(a => {
                      return {
                        label: `${a.description}`,
                          value: a.id
                      }
                    }) : ''}
                    onChange={(e) => this.onFormInputChange(e, 'region')}
                  />
              </div>
              :
              <div className="input input-whole">
                <label htmlFor="region">region</label>
                <span>{i18n.t('region')}</span>
                <span className={`error-form ${this.state.error_form.region ? 'show' : ''}`}>{i18n.t('mandatory_field')}</span>
                <input
                  id="region"
                  type="text"
                  value={this.state.form.region}
                  onChange={(e) => this.onFormInputChange(e, 'region')}
              ></input>
              </div>
            }
            
            <div>
              {
                this.state.save_address
                  ? <FontAwesomeIcon icon={faCheckSquare}
                      onClick={() => this.setState({ ...this.state, save_address: !this.state.save_address })}
                    />
                  : <FontAwesomeIcon
                      icon={faSquare}
                      onClick={() => this.setState({ ...this.state, save_address: !this.state.save_address })}
                    />
              }
              &nbsp;
              <span
                onClick={() => this.setState({ ...this.state, save_address: !this.state.save_address })}
                style={{cursor: "pointer"}}
              >
                {
                  i18n.t("save_address_for_later")
                }
              </span>
            </div>
            
            
            
          </div>
          }
          <div className="body">
            <div className="input w-100">
              <label htmlFor="notes">notes</label>
              <span>{i18n.t('notes')}</span>
              <textarea
                id="notes"
                value={this.state.form.notes}
                rows="2"
                onChange={(e) => this.onFormInputChange(e, 'notes')}
              ></textarea>
            </div>
          </div>
          {
            this.checkDiscounts() && this.state.user_lookups && this.state.user_lookups.coupons && this.filterCoupons(this.state.user_lookups.coupons).length > 0 &&
            <div className="body">
              <div className="input w-80 coupon-input">
                <label>coupons</label>
                <span>{i18n.t("coupons")}</span>
                {
                  this.filterCoupons(this.state.user_lookups.coupons).map(s => {
                    return <div>
                        {
                          this.state.form.activeCoupon && this.state.form.activeCoupon.id === s.id
                            ? <FontAwesomeIcon 
                                icon={faCheckSquare} 
                                onClick={() => this.setState({ ...this.state, form: { ...this.state.form, activeCoupon: null } })}
                              />
                            : <FontAwesomeIcon
                                icon={faSquare}
                                onClick={() => this.setState({ ...this.state, form: { ...this.state.form, activeCoupon: s } })}
                              />
                        }
                        &nbsp;
                        <span
                          onClick={() => this.setState({ ...this.state, form: { ...this.state.form, activeCoupon: s } })}
                        >
                          Κουπόνι -{s.discount_price}€
                        </span>
                      </div>
                  })
                }
              </div>
            </div> 
          }
          <div className="body" style={{position: 'relative'}}>
            
                {
                  !this.state.shipping_choices_loaded &&
                  <div className='shippping-loading-container'>
                    <img src={loadingImage}/>
                  </div>
                }
                {
                this.state.shipping_choices &&
                <div className="input checkbox">
                  <span>{i18n.t('deliver_method')}</span>
                  <span className={`error-form ${this.state.error_form.shippingMethod ? 'show' : ''}`}>{i18n.t('mandatory_field')}</span>
                    <div>
                  
                  {
                    this.state.shipping_choices.map(s => {
                      return <div>
                        {
                          this.state.form.shippingMethod === s.choice
                            ? <FontAwesomeIcon icon={faCheckSquare} />
                            : <FontAwesomeIcon
                                icon={faSquare}
                                onClick={() => this.setState({ ...this.state, form: { ...this.state.form, error_form: { ...this.state.error_form, paymentMethod: false }, shippingMethod: s.choice, paymentMethod: null } })}
                              />
                        }
                        &nbsp;
                        <span
                          onClick={() => this.setState({ ...this.state, form: { ...this.state.form, error_form: { ...this.state.error_form, paymentMethod: false }, shippingMethod: s.choice, paymentMethod: null } })}
                        >
                          {
                            s.name ?
                            i18n.get_name(s) :
                            i18n.t(s.choice)
                          }
                          {
                            (s.information && s.information_en && s.information != ''&& s.information_en != '') &&
                            <div className="more-info-wrapper">
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                size="sm"
                                className={`more-info`} 
                              />
                              <div className='more-info-content hide'>{i18n.get_generic(s, 'information')}</div>
                            </div>
                          }
                        </span>
                      </div>
                    })
                  }
                  </div>
                </div>
                }

                {
                this.state.shipping_choices && this.state.form.shippingMethod && typeof this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod)[0] != 'undefined' &&
                this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod)[0].user_choices.length > 0 &&
                <div className="input checkbox">
                  <span>{i18n.t('deliver_method')}</span>
                  <span className={`error-form ${this.state.error_form.shippingUserMethod ? 'show' : ''}`}>{i18n.t('mandatory_field')}</span>
                    <div>
                  
                  {
                    this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod)[0].user_choices.map(s => {
                      return <div>
                        {
                          this.state.form.shippingUserMethod === s
                            ? <FontAwesomeIcon icon={faCheckSquare} />
                            : <FontAwesomeIcon
                                icon={faSquare}
                                onClick={() => this.setState({ ...this.state, error_form: { ...this.state.error_form, shippingUserMethod: false }, form: { ...this.state.form, shippingUserMethod: s } })}
                              />
                        }
                        &nbsp;
                        <span
                          onClick={() => this.setState({ ...this.state, error_form: { ...this.state.error_form, shippingUserMethod: false }, form: { ...this.state.form, shippingUserMethod: s } })}
                        >
                          {
                            
                            i18n.t(s)
                          }
                        </span>
                      </div>
                    })
                  }
                  </div>
                </div>
                }
                {
                this.state.shipping_choices && this.state.form.shippingMethod && typeof this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod)[0] != 'undefined' &&
                this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod)[0].manual_input == true &&
                  <div className='body'>
                    <span style={{width: '100%'}}>{i18n.t('extra_shipping_information')}</span>
                    <div className="input input-whole">
                      <label htmlFor="userShippingName">userShippingName</label>
                      <span>{i18n.t('user_shipping_name')}</span>
                      <span className={`error-form ${this.state.error_form.userShippingName ? 'show' : ''}`}>{i18n.t('mandatory_field')}</span>
                      <input
                        id="userShippingName"
                        type="text"
                        value={this.state.form.userShippingName}
                        onChange={(e) => this.onFormInputChange(e, 'userShippingName')}
                      ></input>
                    </div>
                    <div className="input input-whole">
                      <label htmlFor="userShippingAddress">userShippingAddress</label>
                      <span>{i18n.t('user_shipping_address')}</span>
                      <span className={`error-form ${this.state.error_form.userShippingAddress ? 'show' : ''}`}>{i18n.t('mandatory_field')}</span>
                      <input
                        id="userShippingAddress"
                        type="text"
                        value={this.state.form.userShippingAddress}
                        onChange={(e) => this.onFormInputChange(e, 'userShippingAddress')}
                      ></input>
                    </div>
                    <div className="input input-whole">
                      <label htmlFor="userShippingPhone">userShippingPhone</label>
                      <span>{i18n.t('user_shipping_phone')}</span>
                      <span className={`error-form ${this.state.error_form.userShippingPhone ? 'show' : ''}`}>{i18n.t('mandatory_field')}</span>
                      <input
                        id="userShippingPhone"
                        type="text"
                        value={this.state.form.userShippingPhone}
                        onChange={(e) => this.onFormInputChange(e, 'userShippingPhone')}
                      ></input>
                    </div>
                    <div className="input input-whole">
                      <label htmlFor="userShippingCounty">userShippingCounty</label>
                      <span>{i18n.t('user_shipping_county')}</span>
                      <span className={`error-form ${this.state.error_form.userShippingCounty ? 'show' : ''}`}>{i18n.t('mandatory_field')}</span>
                      <input
                        id="userShippingCounty"
                        type="text"
                        value={this.state.form.userShippingCounty}
                        onChange={(e) => this.onFormInputChange(e, 'userShippingCounty')}
                      ></input>
                    </div>
                  </div>
                }
            
            
              {
                this.state.shipping_choices && this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod).length > 0 && 
                <div className="input checkbox">
                  <span>{i18n.t('payment_method')}</span>
                  <span className={`error-form ${this.state.error_form.paymentMethod ? 'show' : ''}`}>{i18n.t('mandatory_field')}</span>
                  
                    {
                      this.state.shipping_choices.filter(s => s.choice == this.state.form.shippingMethod)[0].payment.map(s => {
                        return <div>
                          {
                            this.state.form.paymentMethod === s.type
                              ? <FontAwesomeIcon icon={faCheckSquare} />
                              : <FontAwesomeIcon
                                  icon={faSquare}
                                  onClick={() => this.setState({ ...this.state, error_form: { ...this.state.error_form, paymentMethod: false }, form: { ...this.state.form, paymentMethod: s.type } })}
                                />
                          }
                          &nbsp;
                          <span
                            onClick={() => this.setState({ ...this.state, error_form: { ...this.state.error_form, paymentMethod: false }, form: { ...this.state.form, paymentMethod: s.type } })}
                          >
                            {
                              s.name ?
                              i18n.get_name(s) :
                              i18n.t(s.type)
                            }
                            {
                              s.iban &&
                              <p style={{margin: 0, fontSize: '12px'}}>IBAN: {s.iban}</p>
                            }
                            {
                              s.bic &&
                              <p style={{margin: 0, fontSize: '12px'}}>BIC: {s.bic}</p>
                            }
                            {
                              s.charge > 0 ?
                              `${s.charge.toFixed(2)} € + ${i18n.t('vat_charge')}` :
                              ''
                            }
                          </span>
                        </div>
                      })
                    }
                </div>
              }  
            </div>
          <div className="footer">
            <button
              type="button"
              className={`btn btn-lg`}
              // disabled={this.state.form.lastname.trim().length === 0 || this.state.form.email.trim().length === 0 || this.state.form.phone.trim().length === 0}
              onClick={this.send}
            >
              <FontAwesomeIcon icon={faCheckDouble} />
              &nbsp;
              {i18n.t('make_order')}
            </button>
          </div>
        </div>
        <div className={`order-sent-message ${this.state.orderSent !== true ? 'hidden' : ''}`}>
          <FontAwesomeIcon icon={faCheckDouble} />
          <span
            style={{textShadow: '4px 0 0 #FAFAFA, -4px 0 0 #FAFAFA, 0 4px 0 #FAFAFA, 0 -4px 0 #FAFAFA, 1px 1px #FAFAFA, -1px -1px 0 #FAFAFA, 1px -1px 0 #FAFAFA, -1px 1px 0 #FAFAFA'}}
          >
            {i18n.t('order_sent_message')}
          </span>
          <div className="d-flex flex-direction-column align-items-center fs-xxl mt-5">
            <span className="position-absolute fs-xs">{i18n.t('order_number')}</span>
            <span>#{this.state.completed_order_id ? this.state.completed_order_id : '5129'}</span>
          </div>
        </div>
        <div className={`basket ${this.state.products.length === 0 ? 'hidden' : ''}`}>
          {
            this.state.products.map((product, i) => {
              return (
                <div
                  className="item"
                  key={i}
                >
                  <div
                    className="image"
                    style={{backgroundImage: `url('${product.thumbnail}')`}}
                  ></div>
                  <div className="details">
                    <div className="name"><Link to={`/products/${product.id}`}>{i18n.get_name(product)}</Link></div>
                    <div className="mt-1 fs-sm">{i18n.t('quantity')} &times;{product.quantity}</div>
                    <div>{(product.quantity * (this.calculatePrice(product))).toFixed(2)}&nbsp;€</div>
                    {/* {
                      this.state.orderSent === false
                        ? <button
                            type="button"
                            className="btn btn-sm btn-outline"
                            onClick={() => this.removeProduct(product)}
                          >
                            {i18n.t('remove_product_from_basket')}
                          </button>
                        : ''
                    } */}
                  </div>
                </div>
              );
            })
          }
          {
            this.state.discount_products.map((product, i) => {
              return (
                <div
                  className="item"
                  key={i}
                >
                  <div
                    className="image"
                    style={{backgroundImage: `url('${product.thumbnail}')`}}
                  ></div>
                  <div className="details">
                    <div className="name"><Link to={`/products/${product.id}`}>{product.name}</Link></div>
                    <div className="mt-1 fs-sm">{i18n.t('quantity')} &times;{product.quantity}</div>
                    <div>{((this.calculateWholeDiscountPrice(product))).toFixed(2)}&nbsp;€</div>
                    {/* {
                      this.state.orderSent === false
                        ? <button
                            type="button"
                            className="btn btn-sm btn-outline"
                            onClick={() => this.removeProduct(product)}
                          >
                            {i18n.t('remove_product_from_basket')}
                          </button>
                        : ''
                    } */}
                  </div>
                </div>
              );
            })
          }
          {
              this.state.invalidProducts && this.state.invalidProducts.length > 0 &&
              <div className='products invalid-products'>
                <h4>{i18n.t("qty_changed_since_last_time_basket_prompt")}</h4>
                {this.state.invalidProducts &&
                  this.state.invalidProducts.sort( (a, b) => {
                    return a.name > b.name ? 1 : -1
                  } ).map((product, i) => {
                    return (
                      <div
                        className="item invalid-item"
                        key={i}
                      >
                        <div
                          className="image"
                          style={{backgroundImage: `url('${product.thumbnail}')`}}
                        ></div>
                        <div className="details">
                          <div className="name"><Link to={`/products/${product.id}`}>{product.name}</Link></div>
                          <div className="mt-1 fs-sm">{i18n.t('quantity')} &times;{product.quantity}</div>
                          {/* {
                            this.state.orderSent === false
                              ? <button
                                  type="button"
                                  className="btn btn-sm btn-outline"
                                  onClick={() => this.removeProduct(product)}
                                >
                                  {i18n.t('remove_product_from_basket')}
                                </button>
                              : ''
                          } */}
                        </div>
                      </div>
                    );
                  })
                }
                <div className='item'>
                  <button className="btn-clear-invalid" onClick={() => basket.removeInvalidProducts()}>{i18n.t('clear')}</button>
                </div>
              </div>
            }
          <div className="subtotal">
            <span>{i18n.t('subtotal')}</span>
            <span>{this.getSubtotal(subtotal, subtotal_discount)}&nbsp;€</span>
          </div>
            {
              picking > 0 
              &&
              <div className="subtotal">
                <span>{i18n.t('picking_charge')}</span>
                <span>{picking.toFixed(2)}&nbsp;€</span>
              </div>
            }
            {
              this.state.form.activeCoupon &&
              <div className="subtotal coupon-subtotal">
                <span>{i18n.t('coupon')}</span>
                <span>-{parseFloat(this.state.form.activeCoupon.discount_price).toFixed(2)}&nbsp;€</span>
              </div>
            }
            {
              subtotal_discount > 0 
              &&
              <div className="subtotal">
                <span>{i18n.t('discount')}</span>
                <span>{mathUtils.round(this.getSubtotal(subtotal, subtotal_discount) - this.getSubtotal(subtotal, subtotal_after_discount_clear) ).toFixed(2)}&nbsp;€</span>
              </div>
            }
            {
              shipping > 0 
              &&
              <div className="subtotal">
                <span>{i18n.t('shipping_charge')}</span>
                <span>{shipping.toFixed(2)}&nbsp;€</span>
              </div>
            }
            {
              payment > 0 
              &&
              <div className="subtotal">
                <span>{i18n.t('payment_charge')}</span>
                <span>{payment.toFixed(2)}&nbsp;€</span>
              </div>
            }
            
          {
            this.state.form.activeCoupon && (grandTotal + subtotal_after_discount) - this.state.form.activeCoupon.discount_price <= 0 ?
          <div className="subtotal">
            <span>{i18n.t('taxes')}</span>
            <span>{(shipping_tax + payment_tax).toFixed(2)}&nbsp;€</span>
          </div>
          :
          <div className="subtotal">
            <span>{i18n.t('taxes')}</span>
            <span>{taxes.toFixed(2)}&nbsp;€</span>
          </div>
          }
          
          {
            this.state.form.activeCoupon ?
            <div className="grandtotal">
              <span className="fw-bold">{i18n.t('grand_total')}</span>
              <span className="fw-bold">{
                                          mathUtils.round((grandTotal + subtotal_after_discount) + whole_charge).toFixed(2) 
                                        }&nbsp;€</span>
            </div> :
            <div className="grandtotal">
             <span className="fw-bold">{i18n.t('grand_total')}</span>
             <span className="fw-bold">{(discount ? mathUtils.round(grandTotal + subtotal_after_discount - discount).toFixed(2) : mathUtils.round(grandTotal + subtotal_after_discount + whole_charge).toFixed(2))}&nbsp;€</span>
           </div>
          }
        </div>
      </div>
      :
      <div className="error-data">
        {i18n.t("error_user_data_checkout")}
      </div>
    );
  }
}

export default CheckoutPage;