import './ContactPage.css';
import React from 'react';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faGlobe, faPhone, faMapMarkerAlt, faLightbulb, faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import request from "../request";
import storage from '../storage';
import gtag_evt from '../gtag';

class ContactPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      showMessage: false, 
      responseStatus:'',  
      responseMessage: '',  
      sending_message: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitContact = this.submitContact.bind(this);
  }

  handleInputChange(event, inputName) {    
    this.setState({
      showMessage: false,
      [inputName]: event.target.value,      
    });

  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }

  submitContact(event) {    
    event.preventDefault();
    let name = this.state.name;
    let email = this.state.email;
    let subject = this.state.subject;
    let message = this.state.message;
    if (
      name == '' ||
      email == '' ||
      subject == '' ||
      message == '' 
    ) {
      this.setState({
        responseStatus: 'missing_fields',     
        messageResponse: i18n.t('fill_all_fields_prompt'),
        showMessage: true
      })
      return;
    }
    if (!this.state.sending_message) {
      this.setState({
        sending_message: true
      }, () => {
        request.post('api/send-email', {  "name" : this.state.name, "email" : this.state.email, "subject" : this.state.subject, "message" : this.state.message} ).then( (response) => {
      
          gtag_evt.gtag('event', 'contact_submit', {
            email: this.state.email,
          })
          
          this.setState({
            showMessage: true,
            responseStatus: response.data.status,     
            messageResponse: response.data.message,
            email: '',
            subject: '',
            message: '',
            name: '',
            sending_message: false
          })
          
        });
            
      })
    }

  }

  componentDidMount(){
    document.title = i18n.t('contact_page_title');
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
  }

  componentDidUpdate() {
    document.title = i18n.t('contact_page_title');
  }

  render() {
    return (

      <div className="contact-page">
         <iframe 
                height="450"
                width="100%"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6054.558428531087!2d22.84440372772883!3d40.645775157886575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a83ba78cd73779%3A0x959a8f98c933b6b1!2zzprOkc6hzpHOk86ZzpHOnc6dzpfOoywgzpEuICYgzpQuLCDOlS7OoC7OlS4!5e0!3m2!1sel!2sgr!4v1651572757703!5m2!1sel!2sgr"
                style={{ border:0 }}
                allowFullScreen={true}
                loading="lazy" >
              </iframe>
        <section className="contact_form">
          <div className="flex-container ">
            <form className='cont-form' onSubmit={this.handleSubmit} >

                <h2>{i18n.t('get_in_touch')}</h2>
                <p>{i18n.t('contact_sub_header')}</p>

                <div className="flex-item-left" >

                  <div className="w-50 col-left">
                    <input type="text" className="form-control" placeholder={i18n.t('whole_name')} id="name" value={this.state.name} onChange={(e) => { this.handleInputChange(e , 'name') }}  />
                  </div>

                  <div className="w-50 col-right">
                    <input id="email" type="email" className="form-control" placeholder="Email" value={this.state.email} onChange={ (e) => {this.handleInputChange(e, 'email') } } />       
                  </div> 

                  <div  className="w-50 col-left">
                    <input type="text" id="subject" className="form-control" placeholder={i18n.t('subject')} value={this.state.subject} onChange={ (e) => {this.handleInputChange(e, 'subject') } } />
                  </div>
                  
                  <div  className="w-100 col-left">
                    <textarea id="message" cols="10" rows="1" className="form-control" placeholder={i18n.t('message')} value={this.state.message} onChange={ (e) => {this.handleInputChange(e, 'message') } }></textarea>                       
                  </div>

                  <button className="btn btn-outline mb-20" type="submit" onClick={this.submitContact} name="submit">
                    {this.state.sending_message ? <FontAwesomeIcon className="spinning-icon" icon={faCircleNotch} /> : ''} {i18n.t('send')}
                  </button>

                </div>

                <div className={`alert ${this.state.showMessage === false ? 'hidden' : ''} ${this.state.responseStatus === 'ok' ? ' alert-success' : 'alert-danger'}`}>                  
                  {this.state.messageResponse}
                </div>

             </form>

            <div className="flex-item-right">    
            <div className="item">
              <FontAwesomeIcon icon={faPhone}  />
              <h3>{i18n.t('phone')}</h3>
              <p>{storage.company.phone1}</p>
            </div>
            <div className="item">
              <FontAwesomeIcon icon={faGlobe} />
              <h3>Email</h3>
              <p>{storage.company.email1}</p>
            </div>
            <div className="item">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <h3><h3>{i18n.t('address')}</h3></h3>
              <p>{i18n.get_text(storage.company.address)},</p>
              <p>{i18n.get_text(storage.company.city)}, {storage.company.zip}</p>
            </div>     
            <div className="item">
              <FontAwesomeIcon icon={faLightbulb} />
              <h3><h3>{i18n.t('gemi')}</h3></h3>
              <p>{i18n.get_text(storage.company.gemi)}</p>
            </div>           
             
            </div> 

          </div>
        </section>

        {/* <section className="contact_details bg-grey">
          <div className="flex-container">
            <div className="item">
              <FontAwesomeIcon icon={faPhone}  />
              <p>{storage.company.phone1}</p>
            </div>
            <div className="item">
              <FontAwesomeIcon icon={faGlobe} />
              <p>{storage.company.email1}</p>
            </div>
            <div className="item">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <p>{i18n.get_text(storage.company.address)},</p>
              <p>{i18n.get_text(storage.company.city)}, {storage.company.zip}</p>
            </div>
          </div>
        </section>   */}
      </div>

    );
  }
}

export default ContactPage;