import './Basket.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import history from '../history';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faTruckMonster } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import NumberPicker from '../number-picker/NumberPicker';
import basket from '../basket';
import discountBasket from '../discountBasket';
import mathUtils from '../mathUtils';
import loadingImage from '../assets/img/loading.gif';

class Basket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      products: [],
      invalidProducts: [],
      productsUpdated: false,
      subtotal: 0,
      ready: false
    };
    
    this.close = this.close.bind(this);
    this.calculatePrice = this.calculatePrice.bind(this);
  }

  componentDidMount() {
    // basket.initializeBasket()
    // discountBasket.initializeBasket()
    basket.validateBasketStorage()
    discountBasket.validateBasket()
    this.unlisten = history.listen((location) => {
      this.setState({
        ...this.state,
        open: false,
      });
      basket.validateBasketStorage()
    });

    this.props.attachListenerOnOpen(() => {
      this.setState({
        ...this.state,
        open: true,
      });
    });

    this.props.attachListenerOnClose(() => {
      this.setState({
        ...this.state,
        open: false,
      });
    });

    this.setState({
      ...this.state,
      products: basket.products,
      invalidProducts: basket.invalidProducts,
      discount_products: discountBasket.products,
      productsUpdated: true,
    });

    this.basketLoadUnmount = basket.onLoad(() => {
      this.setState({
        ...this.state,
        ready: true
      });
    });

    this.basketUnmount = basket.onChange(() => {
      this.setState({
        ...this.state,
        products: basket.products,
        invalidProducts: basket.invalidProducts,
        discount_products: discountBasket.products,
        productsUpdated: true,
      });
    });

    this.discountBasketUnmount = discountBasket.onChange(() => {
      this.setState({
        ...this.state,
        products: basket.products,
        discount_products: discountBasket.products,
        productsUpdated: true,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.productsUpdated) {
      discountBasket.validateBasketStorage()
      let subtotal = 0;
      this.state.products.forEach((product) => {
        subtotal += (this.calculatePrice(product)) * product.quantity;
      });

      this.state.discount_products.forEach((product) => {
        subtotal += (this.calculateDiscountPrice(product)) * product.quantity;
      });

      this.setState({
        ...this.state,
        subtotal: subtotal,
        productsUpdated: false,
      });

      const getThumbnailsPromises = [];
      const p = this.state.products.slice();
      for (let i = 0; i < p.length; i++) {
        const thumbnail = new Image();
        getThumbnailsPromises.push(new Promise((resolve, reject) => {
          thumbnail.onload = () => {
            resolve();
          };

          thumbnail.onerror = ((i) => {
            return () => {
              p[i].thumbnail = '/no-product-image-image.png';
              resolve();
            };
          })(i);
        }));

        thumbnail.src = p[i].thumbnail;
      }

      Promise.all(getThumbnailsPromises).then(() => {
        this.setState({
          ...this.state,
          products: p,
        });
      });
    }
  }

  componentWillUnmount() {
    this.unlisten();
    this.basketUnmount();
    this.discountBasketUnmount();
    this.basketLoadUnmount();
  }

  close() {
    this.setState({
      ...this.state,
      open: false,
    });
  }

  
  calculatePrice(product) {
    let price = 0;
    let total_qty = this.state.products.map((prod) => {
      return prod.id == product.id ? prod.quantity : 0;
    }).reduce((p, c) => {
      return p + c;
    }, 0);
    
    if(product.prices){
      let valid_prices = product.prices.filter( (p) => {
        return p.qty <= total_qty
      })
      if(valid_prices.length > 0){
        let single_price = valid_prices.reduce(function (p, v) {
          return ( p.qty > v.qty ? p : v );
        });
        return mathUtils.round(single_price.price);
      }else{
        return 0;
      }
    }else{
      this.removeProduct(product);
    }
  }

  calculateDiscountPrice(product) {
    let price = product.price - ( (product.discount.percentage / 100) * product.price );
    
    return mathUtils.round(price);
  }

  

  getPicking(product){
    return product.picking_charge
  }

  calculateTotalProductPrice(product){
    
    return mathUtils.round( mathUtils.round(this.calculatePrice(product) * product.quantity) + mathUtils.round(this.getPicking(product)) )
  }

  calculateTotalProductDiscountPrice(product) {
    return ( mathUtils.round( (product.price * product.quantity) - ( (product.discount.percentage / 100) * (product.price * product.quantity) ) ) )
  }

  calculatePickingPrice(){
    
    let price = 0;
    this.state.products.map( (product) => {
      
       
          
        price += mathUtils.round(this.getPicking(product))
          
        
      
    } )
    
    return mathUtils.round(price)
  }

  removeProduct(product) {
    basket.removeProduct(product, product.variant_id);
  }
  removeDiscountProduct(product) {
    discountBasket.removeProduct(product, product.variant_id, product.discount);
  }
  productsLengthSum() {
    let products = this.state.products ? Object.keys(this.state.products).length : 0;
    let discount_products = this.state.discount_products ? Object.keys(this.state.discount_products).length : 0;

    return products + discount_products;
  }

  render() {
    return (
      <div className={`basket ${this.state.open ? 'open' : ''}`}>
        <div
          className="backdrop"
          onClick={this.close}
        ></div>
        <div className="container">
          <div className="header">
            <h3>{i18n.t('your_basket')}&nbsp;({this.productsLengthSum()})</h3>
            <FontAwesomeIcon
              icon={faTimes}
              className="btn-close"
              onClick={this.close}
            />
          </div>
          {
            !this.state.ready &&
            <div className="basket-loading">
              <img src={loadingImage}/>
            </div>
          }
          <div className="products">
            
            {
              Object.keys(this.state.products).length === 0
                ? <div className="fs-sm text-center">{i18n.t('empty_basket')}</div>
                : ''
            }
            
            { 
            this.state.products &&
              this.state.products.sort( (a, b) => {
                return a.name > b.name ? 1 : -1
              } ).map((product, i) => {
                return (
                  <div
                    className="product"
                    key={i}
                  >
                    <div
                      className="image"
                      style={{backgroundImage: "url('" + product.thumbnail + "')"}}
                    ></div>
                    <div className="info">
                      <Link
                        to={`/products/${product.id}`}
                        className="name"
                      >
                        <span className="fs-sm">&times;{product.quantity}</span>
                        &nbsp;
                        {i18n.get_name(product)}
                      </Link>
                      <span className="price">
                        <span>{product.quantity} {i18n.t("qty_metric")}</span> ({this.calculatePrice(product)}&nbsp;€/{i18n.t("qty_metric_single")})
                      </span>
                      {
                        this.getPicking(product) > 0 &&
                        <span className="price">
                          {this.getPicking(product)}&nbsp;€ {i18n.t("picking_charge")}
                        </span>
                      }
                      <span className="price" style={{margin: '5px 0', fontWeight: 600}}>
                        {this.calculateTotalProductPrice(product)}&nbsp;€ {i18n.t("total")}
                      </span>
                      <div className="controls">
                        
                        <button
                          type="button"
                          className="btn btn-sm btn-outline"
                          onClick={() => this.removeProduct(product)}
                        >
                          {i18n.t('remove_product_from_basket')}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            }
            { 
            this.state.discount_products &&
              this.state.discount_products.sort( (a, b) => {
                return a.name > b.name ? 1 : -1
              } ).map((product, i) => {
                return (
                  <div
                    className="product"
                    key={i}
                  >
                    <div
                      className="image"
                      style={{backgroundImage: "url('" + product.thumbnail + "')"}}
                    ></div>
                    <div className="info">
                      <Link
                        to={`/products/${product.id}`}
                        className="name"
                      >
                        <span className="fs-sm">&times;{product.quantity}</span>
                        &nbsp;
                        {i18n.get_name(product)}
                      </Link>
                      <span className="price">
                        <span>{product.quantity} {i18n.t("qty_metric")}</span> {i18n.t("from")} <span className='original-price'>{parseFloat(product.price).toFixed(2)} €</span> {this.calculateDiscountPrice(product) > 0 ? '(' + this.calculateDiscountPrice(product).toFixed(2) + ' €/'+i18n.t("qty_metric_single")+')' : <b>{i18n.t("free")}</b>}
                      </span>
                      <span className="price" style={{margin: '5px 0', fontWeight: 600}}>
                        {this.calculateTotalProductDiscountPrice(product) > 0 ? this.calculateTotalProductDiscountPrice(product).toFixed(2) + '€ ' + i18n.t("total") : ''}
                      </span>
                      <div className="controls">
                        
                        <button
                          type="button"
                          className="btn btn-sm btn-outline"
                          onClick={() => this.removeDiscountProduct(product)}
                        >
                          {i18n.t('remove_product_from_basket')}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
          {
              this.state.invalidProducts && this.state.invalidProducts.length > 0 &&
              <div className='products invalid-products'>
                <h4>{i18n.t("qty_changed_since_last_time_basket_prompt")}</h4>
                {this.state.invalidProducts &&
                  this.state.invalidProducts.sort( (a, b) => {
                    return a.name > b.name ? 1 : -1
                  } ).map((product, i) => {
                    return (
                      <div
                        className="product invalid-product"
                        key={i}
                      >
                        <div
                          className="image"
                          style={{backgroundImage: "url('" + product.thumbnail + "')"}}
                        ></div>
                        <div className="info">
                          <Link
                            to={`/products/${product.id}`}
                            className="name"
                          >
                            <span className="fs-sm">&times;{product.quantity}</span>
                            &nbsp;
                            {i18n.get_name(product)}
                          </Link>
                          <span className="price">
                            <span>{product.quantity} {i18n.t("qty_metric")}</span>
                          </span>
                          
                        </div>
                      </div>
                    );
                  })
                }
                <button className="btn-clear-invalid" onClick={() => basket.removeInvalidProducts()}>{i18n.t('clear')}</button>
              </div>
            }
          <div className="amounts">
            <div className="subtotal">
              <span>{i18n.t('subtotal')}</span>
              <span>{this.state.subtotal.toFixed(2)}&nbsp;€</span>
            </div>

            <div className="subtotal">
              <span>{i18n.t('picking_charge')}</span>
              <span>{this.calculatePickingPrice().toFixed(2)}&nbsp;€</span>
            </div>

            <div className="subtotal total">
              <span>{i18n.t('total')}</span>
              <span>{parseFloat(parseFloat(this.state.subtotal.toFixed(2) ) + parseFloat(this.calculatePickingPrice()) ).toFixed(2)}&nbsp;€</span>
            </div>
          </div>
          <div className="controls">
            <Link
              to="/checkout"
              className="btn-checkout"
            >
              {i18n.t('continue_to_checkout')}
            </Link>
            <Link
              to="/basket"
              className="btn-cart"
            >
              {i18n.t('view_cart')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Basket;