import config from "./config";

class I18n {
  language = 'el';
  languageChangeFns = [];
  translations = {
    en: {
      discount_scale_reason_text: 'We provided a discount price scale just for you',
      select_a_choice_on_how_you_found_us_fields: 'Please select a choice on "How you found out about us" section',
      describe_how_you_found_us: 'Describe how you found us',
      'Αναζήτηση Google': 'Google search',
      'Επίσκεψη πωλητή': 'Seller visit',
      'Από κάποιον τρίτο': 'From a third party',
      'Άλλο': 'Other',
      how_you_found_us: 'How you found out about us?',
      gemi: 'G.E.MI.',
      download_items: "Download items",
      api_contact_prompt: "To request API access or for more information contact us at",
      api_link_prompt: "Documentation for the API can be found ",
      api_description: "In addition to the datafeed, we also provide a complete API for specialized solutions. Using the API you can access and update specific data according to your needs.",
      xml_note: "Daily call limit for updates, if you automate the procedure, is 10 calls",
      xml_note_header: "Note: ",
      xml_warning: "The last part of the link is your private key (token) to access the datafeed. Please keep your private key private",
      xml_description: "The following link provides the list of items in xml format with all the necessary information in xml format. With xml you can automate the process of registering our products on your site.",
      datafeed_information: "Datafeed information and API details",
      download: "Download",
      login_to_download_msg: "You must sign in or register in order to download product files",
      coming_soon: "Coming soon new codes",
      qty_changed_since_last_time_basket_prompt: "Those products are no longer available",
      coupon: "Coupon",
      coupon_lower: "coupon",
      personal_offer_products_title: "Offers just for you",
      discount_on_item_price: "discount on item price",
      catalogs_upper: 'PRICE CATALOGS',
      catalogs: 'Price catalogs',
      discounts_and_gifts: 'Discounts and gifts',
      restore_password_title: 'Restore password',
      submit_forgot: 'Send',
      forgot_password_prompt: 'Please fill your Tax Registration Number in the field below',
      discover_our_offers: "Discover our offers",
      about_us_page_title: 'About us',
      balance: "Current balance",
      profile: "Profile",
      error_user_data_checkout: 'It seems some mandatory data are missing from your account so you can\'t continue with your order. Please contact our customers support at info@present-team.eu',
      accept_terms_prompt: 'You must accept the terms of use to continue',
      invalid_email: 'Please submit a valid e-mail address',
      fill_all_fields_prompt: 'Please fill all mandatory fields',
      you_registered_succesfully: 'Registration completed. An e-mail will be sended to the specified address with more information',
      more: "More",
      other_filter: 'Other filters',
      for_specific_variants: 'for specific variants',
      new_arrivals_products_title: 'New arrivals',
      best_seller_products_title: 'Best sellers',
      here: 'here', //Μόλις αφαιρέσατε ποσότητα από προϊόν το οποίο σχετίζεται με ΕΚΠΤΩΤΙΚΟ ΕΙΔΟΣ ή ΔΩΡΟ . Παρακαλώ επαναπροσδιορίστε τις νέες ποσότητες Πατώντας
      removed_discount_prompt: 'You have just removed a quantity from a product that is related to a DISCOUNT or GIFT. Please redefine the new quantities by pressing',
      from: "From",
      payment_method_cost: "Payment method cost",
      shipping_method_cost: "Shipping method cost",
      shipping_method: "Shipping method",
      address_data: "Shipping address information",
      order_data: "Order information",
      order: 'Order',
      password_too_small: 'Password must be at least 7 characters',
      password_missmatch: 'Please set the same password in the field "Confirm password"',
      something_went_wrong_try_later: 'Something went wrong. Please try later',
      login: 'Login',
      restore_password_changed: 'Password updated',
      restore_password_button: 'Change password',
      restore_password_on_header: 'Restore password', 
      link_expired: 'Requested link has expired',
      sended_restore_email: 'We sended instructions of recovery in your e-mail',
      company_delivery_name_optional: 'Delivery address company (optional)',
      wholename: 'Full name',
      setted: 'Setted',
      edit_discount: "Edit discount",
      from_your_order_at_least: "From your order of at least",
      you_earned: "you earned",
      no_active_discounts_prompt: 'You don\'t have any active discounts',
      you_have: "You have",
      active_discounts: "active discount",
      active_discounts: "active discounts",
      signup_on_directions: 'Fill all the fields. All fields are required.',
      extra_shipping_information: "Additional transshipment company information",
      user_shipping_county: "Transport company county",
      user_shipping_address: "Transport company address",
      user_shipping_phone: "Transport company phone",
      user_shipping_name: "Transport company name",
      mandatory_field: 'This field is mandatory',
      speedex: 'Speedex',
      acs: 'ACS',
      elta: 'ΕΛΤΑ',
      geniki_taxudromiki: 'Γενική ταχυδρομική',
      sending_order:"Sending order",
      save_address_for_later: "Save address for future orders",
      same_address_as_billing: "Shipping address is the same as billing address",
      you_received: "You received",
      free: "free",
      basket_updated: "Basket updated",
      price_category_changed_to: "Price category changed to",
      chosen_variants: "Chosen variants",
      price_per_item: "Price per unit",
      size: "Size",
      color: "Color",
      qty_metric: "pcs",
      qty_metric_single: "pcs",
      expected_later: "Expected > month",
      expected_month: "Expected in a month",
      expected_week: "Expected in a week",
      available_qtys: "Available",
      order_qtys: "Order (qty)",
      choose_size: "Choose size",
      choose_color: "Choose color",
      select_quantities: "Select quantities",
      same_qty_for_product: "for the same quantity for product",
      qty_for_product: "pcs for product",
      gift: "gift",
      for: "for",
      discount_lower: "discount",
      you_earn: "you earn",
      pieces: "pcs",
      by_buying: "By buying",
      edit_address: "Edit address",
      date: "Date",
      confirm_delete: "Confirm deletion",
      delete_address_prompt: "Do you wish to delete this address",
      new_address_inserted: "Address has been added",
      delete: "Delete",
      edit: "Edit",
      billing_address: "Billing address",
      add_new_address: "Add new address",
      addresses: "Addresses",
      confirm_password: "Confirm Password",
      old_password: "Old Password",
      new_password: "New password",
      change_password: "Change password",
      cell: "Cell Number",
      personal_details: "Personal Details",
      predefined_address: "Predefined address",
      fill_all_fields: "Please fill all fields with *",
      shipping_charge: 'Shipping charge',
      payment_charge: 'Payment method charge',
      vat_charge: 'VAT Charge',
      deposit_ing: 'Deposit on Ing Belgium bank',
      deposit_peiraios: 'Deposit on Peiraios bank',
      deposit_eurobank: 'Deposit on Eurobank',
      deposit_alphabank: 'Deposit on Alphabank',
      deposit: 'Deposit on bank',
      pay_on_delivery: 'Pay on delivery',
      on_credit: 'On credit',
      our_company_choice: "Courier chosen by the sender",
      customer_choice: "Courier chosen by the receipient",
      customer_choice_transport: "Transport chosen by the receipient",
      get_from_shop_choice: "Pick up from store",
      courier_company_choice: "Transport company choice of our choice",
      choose_address: "Choose a saved address",
      signup_personal: 'Personal data',
      signup_company: 'Company data',
      signup_address: 'Company invoicing address',
      tax_registration_number: 'Tax registration number',
      doy: 'ΔΟΥ',
      activity_code: 'Activity',
      region: 'Region',
      country: 'Country',
      restore_password_page_title: 'Restore account - ' + config.eshop_title,
      home_page_title: config.eshop_title,
      account_page_title: 'My account - ' + config.eshop_title,
      basket_page_title: 'Basket - ' + config.eshop_title,
      blog_page_title: 'Blog - ' + config.eshop_title,
      categories_page_title: 'Categories - ' + config.eshop_title,
      checkout_page_title: 'Checkout - ' + config.eshop_title,
      downloads_page_title: 'Downloads - ' + config.eshop_title,
      contact_page_title: 'Contact us - ' + config.eshop_title,
      product_page_title: config.eshop_title,
      products_page_title: 'Our products - ' + config.eshop_title,
      signin_page_title: 'Sign in - ' + config.eshop_title,
      signup_page_title: 'Sign up - ' + config.eshop_title,
      terms_page_title: 'Terms of use - ' + config.eshop_title,
      picking_charge: 'Picking charge',
      have_found: '',
      have_found2: 'Products found',
      from_qty: 'From',
      from_qty_header: 'Quantity',
      price_header: 'Price',
      pcs: 'pcs',
      visit_time: 'Visit time',
      call_us: 'Call us',
      monday_to_friday_and_hours: 'Mon-Fri 09:00-17:00',
      company_address: 'Skiathou 2, Ntepo',
      home_page: 'Home',
      products_navigation: 'Products',
      categories: 'Categories',
      basket: 'Basket',
      contact: 'Contact',
      blog: 'Blog',
      search: 'Search',
      send: 'Send',
      close: 'Close',
      shop_by_category: 'Shop by Category',
      featured_products_title: 'Featured',
      favourite_products_title: 'Favourites',
      seen_products_title: 'Seen Products',
      products: 'products',
      get_in_touch: 'Get in Touch',
      whole_name: 'Full name',
      subject: 'Subject',
      message: 'Message',
      contact_sub_header: 'How can we help you?',
      home: 'Home',
      add_to_basket: 'Add to basket',
      products_on_header: 'Products Page',
      categories_on_header: 'Shop Categories',
      contact_on_header: 'Contact Page',
      basket_on_header: 'Your Basket',
      blog_on_header: 'Blog',
      signin_on_header: 'Sign in',
      signup_on_header: 'Sign up',
      checkout_on_header: 'Complete Order',
      your_basket: 'Your Basket',
      subtotal: 'Subtotal',
      total: 'Total',
      continue_to_checkout: 'Continue to Checkout',
      view_cart: 'View Cart',
      remove_product_from_basket: 'Remove',
      empty_basket: 'Your basket is empty',
      product_added: 'Product added',
      product_added_error: 'Select the attributes of the product first',
      to_your_basket: 'to your basket',
      add_to_favourite: 'Add to Favourite',
      signin: 'Sign In',
      signout: 'Sign Out',
      my_account: 'My Account',
      support: 'Support',
      help: 'Help',
      contact_us: 'Contact us',
      feedback: 'Feedback',
      unsubscribe: 'Unsubscribe',
      policies: 'Policies',
      privacy_policy: 'Privacy Policy',
      terms_of_use: 'Terms of Use',
      shipping: 'Shipping',
      subcategories: 'Subcategories',
      return_policy: 'Return',
      stay_up_to_date: 'Stay up to Date',
      newsletter_info: '*Subsribe to our newsletter to receive offers, and latest news.',
      subscribe: 'Subsribe',
      your_email: 'Your Email',
      name: 'Name',
      lastname: 'Lastname',
      email: 'Email',
      password: 'Password',
      remember_me: 'Remember me',
      dont_have_an_account: `You don't have an account?`,
      signin_button: 'Sign in',
      signup: 'Sign up',
      alternatively: 'Alternatively',
      forgot_password: 'Forgot Password?',
      register_accept_terms: 'By register, I read & accept the terms.',
      create_account_button: 'Create Account',
      have_an_account: 'Have an account?',
      login_here: 'Login here',
      filters: 'Filters',
      sort_by: 'Sort by',
      price: 'Price',
      price_low_to_high: 'Price, low to high',
      price_high_to_low: 'Price, high to low',
      image: 'Image',
      product_name: 'Product Name',
      unit_price: 'Unit Price',
      quantity: 'Qty',
      action: 'Action',
      order_total: 'Order Total',
      taxes: 'Taxes',
      grand_total: 'Grand Total',
      continue_shopping: 'Continue Shopping',
      empty_basket: 'There are no products in your basket',
      shipping_address: 'Shipping Address',
      firstname: 'Firstname',
      lastname: 'Lastname',
      address: 'Address',
      city: 'City',
      zip: 'Zip',
      phone: 'Phone',
      make_order: 'Make Order',
      invoice_details: 'Invoice Details',
      invoice_type: 'Invoice Type',
      invoice_type_receipt: 'Receipt',
      invoice_type_invoice: 'Invoice',
      company_name: 'Company Name',
      vat: 'Vat Number',
      tax_office: 'Tax Office',
      profession: 'Profession',
      payment_method: 'Payment Method',
      payment_method_on_delivery: 'On Delivery',
      payment_method_deposit: 'Bank Deposit',
      payment_method_online: 'Online Card',
      fetching_categories: 'Fetching Categories',
      no_products_in_list: 'No products added to this list',
      description: 'Description',
      change_header_to_fixed_tooltip: 'Make menu sticky to the top',
      change_header_to_normal_tooltip: 'Make menu back to normal',
      order_sent_message: "Your order has been placed successfuly.",
      input_coupon: "Enter coupon",
      discount: 'Discount',
      deliver_method: "Delivery Method",
      deliver_method_courier: "ACS",
      note: 'Note',
      date_high_to_low: 'Newest',
      no_products_found: 'No products found',
      availability: 'Available',
      notes: 'Notes',
      product_added_as_favourite: 'Product added as favourite',
      remove_from_favourite: 'Remove from favourite',
      choose: 'Choose',
      clear: 'Clear',
      building_floor: 'Building Floor',
      delivery_point_type: 'Delivery Point',
      products_view_type: 'Προβολή',
      grid: 'Grid',
      list: 'List',
      order_number: 'Code',
      delivery_point_type_office_hours: 'Please tell us the working hours',
      personal_data: "Personal data",
      change_password: "Change password",
      orders: "Orders",
      favorites: "Whishlist",
      addressf: "ADDRESS",
      phonef: "PHONE",
      hoursf: "OPEN HOURS",
    },
    el: {
      discount_scale_reason_text: 'Η τιμή κλίμακας αποτελεί έκπτωση μόνο για εσάς',
      select_a_choice_on_how_you_found_us_fields: 'Παρακαλώ επιλέξτε τον τρόπο που βρήκατε την εταιρία μας',
      describe_how_you_found_us: 'Περιγράψτε τον τρόπο που μας βρήκατε',
      'Αναζήτηση Google': 'Αναζήτηση Google',
      'Επίσκεψη πωλητή': 'Επίσκεψη πωλητή',
      'Από κάποιον τρίτο': 'Από κάποιον τρίτο',
      'Άλλο': 'Other',
      how_you_found_us: 'Πώς μας βρήκατε;',
      gemi: 'Γ.Ε.ΜΗ.',
      download_items: "Λήψεις ειδών",
      api_contact_prompt: "Για αίτηση πρόσβασης στο API ή και για περισσότερες πληροφορίες επικοινωνήστε μαζί μας στο",
      api_link_prompt: "Το documentation για το API θα το βρείτε ",
      api_description: "Εκτός από το datafeed παρέχουμε και πλήρες API για εξειδικευμένες λύσεις. Με την χρήση του API μπορείτε να προσπελάσετε και να ενημερώνετε συγκεκριμένα δεδομένα σύμφωνα με τις ανάγκες σας.",
      xml_note: "Το ημερήσιο όριο των κλήσεων για ενημερώσεις, εαν αυτοματοποιήσετε την διαδικασία, είναι 10 κλήσεις",
      xml_note_header: "Σημείωση: ",
      xml_warning: "Το τελευταίο κομμάτι του link είναι το προσωπικό σας κλειδί (token) πρόσβασης στο datafeed. Παρακαλείστε να κρατήσετε κρυφό το προσωπικό σας κλειδί",
      xml_description: "Στο παρακάτω link παρέχεται σε μορφή xml ο κατάλογος των ειδών με όλες τις αναγκαίες πληροφορίες σε μορφή xml. Με το xml μπορείτε να αυτοματοποιήσετε την διαδικασία καταγραφής των προϊόντων μας στο site σας.",
      datafeed_information: "Πληροφορίες τροφοδοσίας δεδομένων και λεπτομέρειες API",
      download: "Λήψη",
      login_to_download_msg: "Για να κατεβάσετε αρχεία είδους θα πρέπει να συνδεθείτε",
      coming_soon: "Προσεχώς νέοι κωδικοί",
      qty_changed_since_last_time_basket_prompt: "Τα παρακάτω προϊόντα δεν είναι πλέον διαθέσιμα",
      coupon: "Κουπόνι",
      coupon_lower: "Κουπόνι",
      personal_offer_products_title: "Προσφορές μόνο για εσάς",
      discount_on_item_price: "έκπτωση στην τιμή τεμαχίου",
      catalogs_upper: 'ΤΙΜΟΚΑΤΑΛΟΓΟΙ',
      catalogs: 'Τιμοκατάλογοι',
      discounts_and_gifts: 'Εκπτώσεις και δώρα',
      restore_password_title: 'Επαναφορά λογαριασμού',
      submit_forgot: 'Αποστολή',
      forgot_password_prompt: 'Εισάγετε το Α.Φ.Μ. σας στο παρακάτω πεδίο και θα σας στείλουμε οδηγίες επαναφοράς του λογαριασμού σας',
      discover_our_offers: "Ανακαλύψτε τις προσφορές μας",
      about_us_page_title: 'Σχετικά με εμάς',
      balance: "Υπόλοιπο",
      profile: "Προφίλ",
      error_user_data_checkout: 'Φαίνεται πως λείπουν κάποια υποχρεωτικά δεδομένα από τον λογαριασμό σας. Για αυτό το λόγο δεν μπορούμε να συνεχίσουμε με την συναλλαγή. Παρακαλώ όπως επικοινωνήσετε με την εξυπηρέτηση πελατών στο info@present-team.eu',
      accept_terms_prompt: 'Παρακαλώ αποδεχτείτε τους όρους χρήσης',
      invalid_email: 'Παρακαλώ συμπληρώστε ένα έγκυρο email',
      fill_all_fields_prompt: 'Παρακαλώ συμπληρώστε όλα τα πεδία',
      you_registered_succesfully: 'Η εγγραφή σας ολοκληρώθηκε. Θα λάβετε σύντομα ένα e-mail στην αναφερόμενη διεύθυνση εγγραφής',
      more: "Περισσότερα",
      other_filter: 'Άλλα φίλτρα',
      for_specific_variants: 'για συγκεκριμένες παραλλαγές',
      new_arrivals_products_title: 'Νέες αφίξεις',
      best_seller_products_title: 'Best sellers',
      here: 'εδώ',
      removed_discount_prompt: 'Μόλις αφαιρέσατε ποσότητα από προϊόν το οποίο σχετίζεται με ΕΚΠΤΩΤΙΚΟ ΕΙΔΟΣ ή ΔΩΡΟ . Παρακαλώ επαναπροσδιορίστε τις νέες ποσότητες Πατώντας',
      from: "Από",
      payment_method_cost: "Κόστος τρόπου πληρωμής",
      shipping_method_cost: "Κόστος μεταφοράς",
      shipping_method: "Τρόπος αποστολής",
      address_data: "Στοιχεία διεύθυνσης αποστολής",
      order_data: "Στοιχεία παραγγελίας",
      order: 'Παραγγελία',
      password_too_small: 'Ο κωδικός πρόσβασης θα πρέπει να έχει μήκος τουλάχιστον 7 χαρακτήρων',
      password_missmatch: 'Παρακαλώ εισάγετε τον ίδιο κωδικό πρόσβασης στο πεδίο "Επιβεβαίωση password"',
      something_went_wrong_try_later: 'Κάτι πήγε στραβά. Προσπαθήστε αργότερα',
      login: 'Συνδεθείτε',
      restore_password_changed: 'Ο κωδικός πρόσβασης ανανεώθηκε επιτυχώς',
      restore_password_button: 'Αλλαγή password',
      restore_password_on_header: 'Επαναφορά κωδικού πρόσβασης', 
      link_expired: 'Ο σύνδεσμος έχει λήξει',
      sended_restore_email: 'Σας αποστείλαμε οδηγίες επαναφοράς στο e-mail σας',
      company_delivery_name_optional: 'Εταιρία διεύθυνσης αποστολής (προαιρετικό)',
      wholename: 'Ονοματεπώνυμο',
      setted: 'Εισάγατε',
      edit_discount: "Επεξεργασία έκπτωσης",
      from_your_order_at_least: "Με την παραγγελία τουλάχιστον",
      you_earned: "κερδίσατε",
      no_active_discounts_prompt: 'Δεν έχετε ενεργές εκπτώσεις',
      you_have: "Έχετε",
      active_discounts: "ενεργή έκπτωση",
      active_discounts: "ενεργές εκπτώσεις",
      signup_on_directions: 'Συμπληρώστε όλα τα παρακάτω πεδία. Όλα τα πεδία είναι υποχρεωτικά',
      extra_shipping_information: "Επιπλέον στοιχεία μεταφορικής μεταφόρτωσης",
      user_shipping_county: "Δήμος μεταφορικής",
      user_shipping_address: "Διεύθυνση μεταφορικής",
      user_shipping_phone: "Τηλ. μεταφορικής",
      user_shipping_name: "Όνομα μεταφορικής",
      mandatory_field: 'Το πεδίο είναι υποχρεωτικό',
      speedex: 'Speedex',
      acs: 'ACS',
      elta: 'ΕΛΤΑ',
      geniki_taxudromiki: 'Γενική ταχυδρομική',
      sending_order:"Αποστολή παραγγελίας σε εξέλιξη",
      save_address_for_later: "Αποθήκευση διεύθυνσης για επόμενες παραγγελίες",
      same_address_as_billing: "Η διεύθυνση αποστολής είναι ίδια με την διεύθυνση τιμολόγησης",
      you_received: "Λάβατε",
      free: "δωρεάν",
      basket_updated: "Το καλάθι ανανεώθηκε",
      price_category_changed_to: "Η κατηγορία τιμής άλλαξε σε",
      chosen_variants: "Επιλεγμένες παραλλαγές",
      price_per_item: "Τιμή ανά τμχ",
      size: "Μέγεθος",
      color: "Χρώμα",
      qty_metric: "τμχ",
      qty_metric_single: "τμχ",
      expected_later: "Αναμενόμενα πέραν του μήνα",
      expected_month: "Αναμενόμενα μήνα",
      expected_week: "Αναμενόμενα εβδομάδας",
      available_qtys: "Διαθέσιμα",
      order_qtys: "Παραγγελία",
      choose_size: "Επιλέξτε μέγεθος",
      choose_color: "Επιλέξτε χρώμα",
      select_quantities: "Επιλογή ποσοτήτων",
      same_qty_for_product: "τα ίδια τμχ για το προϊόν",
      qty_for_product: "τμχ για το προϊόν",
      gift: "δώρο",
      for: "για",
      discount_lower: "έκπτωση",
      you_earn: "κερδίζετε",
      pieces: "τμχ",
      by_buying: "Αγοράζοντας",
      edit_address: "Επεξεργασία διεύθυνσης",
      date: "Ημερομηνία",
      confirm_delete: "Επιβεβαίωση διαγραφής",
      delete_address_prompt: "Θέλετε να διαγράψετε την διεύθυνση",
      new_address_inserted: "Η διεύθυνση προστέθηκε",
      delete: "Διαγραφή",
      edit: "Επεξεργασία",
      billing_address: "Διεύθυνση τιμολόγησης",
      add_new_address: "Προσθήκη νέας διεύθυνσης",
      addresses: "Διευθύνσεις",
      confirm_password: "Επιβεβαίωση Password",
      old_password: "Παλαιό Password",
      new_password: "Νέο password",
      change_password: "Αλλαγή password",
      cell: "Κινητό",
      personal_details: "Προσωπικά στοιχεία",
      predefined_address: "Προκαθορισμένη διεύθυνση",
      fill_all_fields: "Παρακαλώ συμπληρώστε όλα τα υποχρεωτικά πεδία με *",
      shipping_charge: 'Χρεώσεις μεταφοράς',
      payment_charge: 'Χρεώσεις τρόπου πληρωμής',
      vat_charge: 'ΦΠΑ',
      deposit_ing: 'Deposit on Ing Belgium bank',
      deposit_peiraios: 'Κατάθεση στην τράπεζα Πειραιώς',
      deposit_eurobank: 'Κατάθεση στην τράπεζα Eurobank',
      deposit_alphabank: 'Κατάθεση στην τράπεζα Alphabank',
      deposit: 'Κατάθεση σε τράπεζα',
      pay_on_delivery: 'Αντικαταβολή',
      on_credit: 'Επι πιστώσει',
      our_company_choice: "Courier επιλογής αποστολέα",
      customer_choice: "Courier επιλογής παραλήπτη",
      customer_choice_transport: "Μεταφορική επιλογής παραλήπτη",
      get_from_shop_choice: "Παραλαβή από το κατάστημα",
      courier_company_choice: "Μεταφορική εταιρία επιλογής μας",
      choose_address: "Επιλέξτε αποθηκευμένη διεύθυνση",
      signup_personal: 'Προσωπικά στοιχεία',
      signup_company: 'Στοιχεία εταιρίας',
      signup_address: 'Διεύθυνση τιμολόγησης',
      tax_registration_number: 'ΑΦΜ',
      doy: 'ΔΟΥ',
      activity_code: 'Δραστηριότητα',
      region: 'Νομός',
      country: 'Χώρα',
      home_page_title: config.eshop_title,
      restore_password_page_title: 'Επαναφορά λογαριασμού - ' + config.eshop_title,
      account_page_title: 'Ο λογαριασμός μου - ' + config.eshop_title,
      basket_page_title: 'Καλάθι - ' + config.eshop_title,
      blog_page_title: 'Blog - ' + config.eshop_title,
      categories_page_title: 'Κατηγορίες - ' + config.eshop_title,
      checkout_page_title: 'Checkout - ' + config.eshop_title,
      downloads_page_title: 'Λήψεις - ' + config.eshop_title,
      contact_page_title: 'Επικοινωνία - ' + config.eshop_title,
      product_page_title: config.eshop_title,
      products_page_title: 'Τα προϊόντα μας - ' + config.eshop_title,
      signin_page_title: 'Σύνδεση - ' + config.eshop_title,
      signup_page_title: 'Εγγραφή - ' + config.eshop_title,
      terms_page_title: 'Όροι χρήσης - ' + config.eshop_title,
      picking_charge: 'Χρέωση picking',
      have_found: 'Βρέθηκαν',
      have_found2: 'προϊόντα',
      from_qty: 'Από',
      from_qty_header: 'Ποσότητα',
      price_header: 'Τιμή',
      pcs: 'τμχ',
      visit_time: 'Ανοιχτά',
      call_us: 'Καλέστε μας',
      monday_to_friday_and_hours: 'Δευ-Παρ 09:00-17:00',
      company_address: 'Σκιάθου 2, Ντεπώ',
      home_page: 'Αρχική',
      products_navigation: 'Προϊόντα',
      categories: 'Κατηγορίες',
      basket: 'Καλάθι Αγορών',
      contact: 'Επικοινωνία',
      blog: 'Blog',
      search: 'Αναζήτηση',
      send: 'Αποστολή',
      close: 'Κλείσιμο',
      shop_by_category: 'Κατηγορίες',
      featured_products_title: 'Προτεινόμενα',
      favourite_products_title: 'Αγαπημένα',
      seen_products_title: 'Είδατε πρόσφατα',
      products: 'προϊόντα',
      get_in_touch: 'Επικοινωνήστε μαζί μας',
      whole_name: 'Ονοματεπώνυμο',
      subject: 'Θέμα',
      message: 'Μήνυμα',
      contact_sub_header: 'Πως μπορούμε να σας εξυπηρετήσουμε',
      home: 'Αρχική',
      add_to_basket: 'Προσθήκη στο καλάθι',
      products_on_header: 'Σελίδα Προϊόντων',
      categories_on_header: 'Κατηγορίες του E-shop μας',
      contact_on_header: 'Σελίδα Επικοινωνίας',
      basket_on_header: 'Το Καλάθι σας',
      blog_on_header: 'Blog',
      signin_on_header: 'Σύνδεση Χρήστη',
      signup_on_header: 'Εγγραφή Χρήστη',
      checkout_on_header: 'Ολοκλήρωση Αγορών',
      your_basket: 'Το Καλάθι σας',
      subtotal: 'Υποσύνολο',
      total: 'Σύνολο',
      subcategories: 'Υποκατηγορίες',
      continue_to_checkout: 'Ολοκλήρωση Παραγγελίας',
      view_cart: 'Σελίδα Καλαθιού',
      remove_product_from_basket: 'Αφαίρεση',
      empty_basket: 'Το καλάθι σας είναι άδειο',
      product_added: 'Το προϊόν προστέθηκε',
      product_added_error: 'Παρακαλώ επιλέξτε πρώτα τα χαρακτηριστικά προϊόντος',
      to_your_basket: 'στο καλάθι σας',
      add_to_favourite: 'Προσθήκη στα Αγαπημένα',
      signin: 'Σύνδεση',
      signout: 'Αποσύνδεση',
      my_account: 'Ο Λογαριασμός μου',
      support: 'Υποστήριξη',
      help: 'Βοήθεια',
      contact_us: 'Επικοινωνία',
      feedback: 'Η Γνώμη σας',
      unsubscribe: 'Απεγγραφή',
      policies: 'Πολιτικές',
      privacy_policy: 'Πολιτική Απορήτου',
      terms_of_use: 'Όροι και Προϋποθέσεις',
      shipping: 'Αποστολή Προϊόντων',
      return_policy: 'Πολιτική Επιστροφών',
      stay_up_to_date: 'Μείνετε Ενημερωμένοι',
      newsletter_info: '*Εγγραφείτε στο ενημερωτικό μας δελτίο για να λαμβάνετε εκπτωτικές προσφορές, και ενημερώσεις για νέα προϊόντα.',
      subscribe: 'Εγγραφή',
      your_email: 'Το Email σας',
      name: 'Όνομα',
      lastname: 'Επώνυμο',
      email: 'Email',
      password: 'Κωδικός',
      remember_me: 'Να με θυμάσαι',
      dont_have_an_account: `Δεν έχετε λογαριασμό;`,
      signin_button: 'Σύνδεση',
      signup: 'Εγγραφή',
      alternatively: 'Εναλλακτικά',
      forgot_password: 'Ξεχάσατε τον κωδικό σας;',
      register_accept_terms: 'Με την εγγραφή, διαβάζω και αποδέχομαι του όρους χρήσης.',
      create_account_button: 'Δημιουργία Λογαριασμού',
      have_an_account: 'Έχετε λογαριασμό;',
      login_here: 'Συνδεθείτε εδώ',
      filters: 'Φίλτρα',
      sort_by: 'Ταξινόμηση',
      price: 'Τιμή',
      price_low_to_high: 'Αύξουσα τιμή',
      price_high_to_low: 'Φθίνουσα τιμή',
      image: 'Εικόνα',
      product_name: 'Όνομα Προϊόντος',
      unit_price: 'Τιμή Μονάδας',
      quantity: 'Ποσότητα',
      action: 'Ενέργειες',
      order_total: 'Σύνολο Παραγγελίας',
      taxes: 'Φ.Π.Α.',
      grand_total: 'Σύνολο',
      continue_shopping: 'Συνέχεια Αγορών',
      empty_basket: 'Δεν υπάρχουν προϊόντα στο καλάθι σας',
      shipping_address: 'Στοιχεία Αποστολής',
      firstname: 'Όνομα',
      lastname: 'Επώνυμο',
      address: 'Διεύθυνση',
      city: 'Πόλη',
      zip: 'Τ.Κ.',
      phone: 'Τηλέφωνο',
      make_order: 'Ολοκλήρωση Αγορών',
      invoice_details: 'Στοιχεία Τιμολογίου',
      invoice_type: 'Τύπος Παραστατικού',
      invoice_type_receipt: 'Απόδειξη',
      invoice_type_invoice: 'Τιμολόγιο',
      company_name: 'Επωνυμία Εταιρείας',
      vat: 'Α.Φ.Μ.',
      tax_office: 'ΔΟΥ',
      profession: 'Επάγγελμα',
      payment_method: 'Τρόπος Πληρωμής',
      payment_method_on_delivery: 'Αντικαταβολή',
      payment_method_deposit: 'Τραπεζική Κατάθεση',
      payment_method_online: 'Πληρωμή Online',
      fetching_categories: 'Φόρτωση Κατηγοριών',
      no_products_in_list: 'Δεν υπάρχουν προϊόντα σε αυτή τη λίστα',
      description: 'Περιγραφή',
      change_header_to_fixed_tooltip: 'Κάντε το menu να παραμείνει στην κορυφή',
      change_header_to_normal_tooltip: 'Γυρίστε το menu πίσω στο κανονικό',
      order_sent_message: "Η παραγγελία σας ολοκληρώθηκε",
      input_coupon: "Εισάγετε εκπτωτικό κωδικό",
      discount: 'Έκπτωση',
      deliver_method: "Μέθοδος Αποστολής",
      deliver_method_courier: "ACS",
      note: 'Σημείωση',
      date_high_to_low: 'Νεότερα',
      no_products_found: 'Δεν βρέθηκαν προϊόντα',
      availability: 'Διαθεσιμότητα',
      notes: 'Σχόλια',
      product_added_as_favourite: 'Το προϊόν προστέθηκε στα αγαπημένα σας',
      remove_from_favourite: 'Αφαίρεση από τα αγαπημένα',
      choose: 'Επιλέξτε',
      clear: 'Καθαρισμός',
      building_floor: 'Όροφος',
      delivery_point_type: 'Σημείο Παραλαβής',
      products_view_type: 'Προβολή',
      grid: 'Πλέγμα',
      list: 'Λίστα',
      order_number: 'Αριθμός Παραγγελίας',
      delivery_point_type_office_hours: 'Παρακαλώ σημειώστε στα σχόλια τις ώρες λειτουργίες τους καταστήματος.',
      personal_data: "Προσωπικά δεδομένα",
      change_password: "Αλλάγη κωδικού",
      orders: "Παραγγελίες",
      favorites: "Αγαπημένα",
      addressf: "ΔΙΕΥΘΥΝΣΗ",
      phonef: "ΤΗΛΕΦΩΝΟ",
      hoursf: "ΩΡΕΣ ΛΕΙΤΟΥΡΓΙΑΣ",
    },
  };

  setLanguage(languageCode) {
    this.language = languageCode;
    this.languageChangeFns.forEach((fn) => {
      fn();
    });
  }

  onLanguageChange(fn) {
    this.languageChangeFns.push(fn);

    return () => {
      this.languageChangeFns.splice(this.languageChangeFns.indexOf(fn), 1);
    };
  }

  t(key) {
    return this.translations[this.language][key];
  }
  onLanguageChangeUnmount(fn) {
    this.languageChangeFns.splice(this.languageChangeFns.indexOf(fn), 1);
  }

  toggle(native, english){
    if(this.language == 'en'){
      return english;
    }else{
      return native;
    }
  }
  get_name(data) {
    if(this.language == 'en'){
      return data.name_en != "" ? data.name_en : data.name;
    }else{
      return data.name;
    }
  }

  get_title(data) {
    if(this.language == 'en'){
      return data.title_en != "" ? data.title_en : data.title;
    }else{
      return data.title;
    }
  }

  get_desc(data) {
    if(this.language == 'en'){
      return data.description_en != "" ? data.description_en : data.description;
    }else{
      return data.description;
    }
  }

  get_mini_desc(data) {
    if(this.language == 'en'){
      return data.miniDescription_en != "" ? data.miniDescription_en : data.miniDescription;
    }else{
      return data.miniDescription;
    }
  }

  get_message(data) {
    if(this.language == 'en'){
      return data.en != "" ? data.en : data.el;
    }else{
      return data.el;
    }
  }

  get_attribute_category(data) {
    if(this.language == 'en'){
      return data.attribute_category_en != "" ? data.attribute_category_en : data.attribute_category;
    }else{
      return data.attribute_category;
    }
  }

  get_custom_category(data){
    if(this.language == 'en'){
      return data.custom_category_text_en != "" ? data.custom_category_text_en : data.custom_category_text;
    }else{
      return data.custom_category_text;
    }
  }

  get_teaser(data){
    if(this.language == 'en'){
      return data.teaser_en != "" ? data.teaser_en : data.teaser;
    }else{
      return data.custom_category_text;
    }
  }

  get_generic(data, field){
    if(this.language == 'en'){
      return data[`${field}_en`] != "" ? data[`${field}_en`] : data[field];
    }else{
      return data[field];
    }
  }

  greeklish(text) {
    let str = text;
    let greek   = ['α','ά','Ά','Α','β','Β','γ', 'Γ', 'δ','Δ','ε','έ','Ε','Έ','ζ','Ζ','η','ή','Η','θ','Θ','ι','ί','ϊ','ΐ','Ι','Ί', 'κ','Κ','λ','Λ','μ','Μ','ν','Ν','ξ','Ξ','ο','ό','Ο','Ό','π','Π','ρ','Ρ','σ','ς', 'Σ','τ','Τ','υ','ύ','Υ','Ύ','φ','Φ','χ','Χ','ψ','Ψ','ω','ώ','Ω','Ώ',' ',"'","'",',']; 
    let english = ['a', 'a','A','A','b','B','g','G','d','D','e','e','E','E','z','Z','i','i','I','th','Th', 'i','i','i','i','I','I','k','K','l','L','m','M','n','N','x','X','o','o','O','O','p','P' ,'r','R','s','s','S','t','T','u','u','Y','Y','f','F','ch','Ch','ps','Ps','o','o','O','O',' ',' ',' ',',']; 
    
    
    for (var i = 0; i < greek.length; i++) {
      while( str.indexOf(greek[i]) !== -1 && greek[i] != english[i]){
        str = str.replace(greek[i], english[i]);    // CONVERT GREEK CHARACTERS TO LATIN LETTERS
      }
      
    }

    return str;
  }

  get_text(text) {
    if(this.language == 'en'){
      return this.greeklish(text);
    }else{
      return text;
    }
  }
}

const i18n = new I18n();
window.i18n = i18n;

export default i18n;