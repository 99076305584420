/*
<svg fill="#000000" height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 298.333 298.333" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 298.333 298.333">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier"> <g> <path d="m248.833,66.166h-248.833v166h248.833l49.5-83-49.5-83zm-176.928,107.198c-3.766,2.73-8.756,4.098-14.978,4.098-6.245,0-11.648-1.57-16.207-4.703-4.558-3.135-6.774-7.561-6.648-13.453l.075-.139h12.43c0,3 0.855,5.35 2.566,6.711 1.715,1.361 4.308,1.996 7.784,1.996 2.645,0 4.615-0.552 5.912-1.608 1.296-1.059 1.946-2.456 1.946-4.169 0-1.938-0.644-3.441-1.927-4.498-1.285-1.059-3.59-2.183-6.914-3.366-6.95-2.191-12.101-4.54-15.451-7.048-3.35-2.506-5.023-6.126-5.023-10.86 0-4.609 1.995-8.339 5.988-11.185 3.99-2.846 9.059-4.268 15.205-4.268 6.094,0 11.086,1.568 14.979,4.703 3.891,3.135 5.772,7.3 5.648,12.237l-.077,.355h-12.391c0-2-0.742-4.101-2.229-5.474-1.485-1.371-3.538-2.124-6.156-2.124-2.57,0-4.587,0.532-6.045,1.669-1.46,1.131-2.191,2.514-2.191,4.176 0,1.615 0.737,2.893 2.208,3.864 1.474,0.971 4.237,2.182 8.293,3.643 6.245,1.863 10.949,4.152 14.111,6.873 3.158,2.719 4.738,6.458 4.738,11.216 0.002,4.837-1.877,8.619-5.646,11.354zm44.901,3.803l-3.137-11h-17.339l-3.1,11h-12.843l17.756-56h13.713l17.793,56h-12.843zm53.194,0h-37v-56h13v46h24v10zm45-46h-25v12h21v10h-21v14h25v10h-38v-56h38v10zm41.583,26.25c-4.557,0-8.25-3.693-8.25-8.25 0-4.557 3.693-8.25 8.25-8.25 4.557,0 8.25,3.693 8.25,8.25 0,4.556-3.693,8.25-8.25,8.25z"/> <polygon points="104.867,136.167 99.162,156.167 110.836,156.167 105.093,136.167 "/> </g> </g>

</svg>
 */
import React from "react";

class SaleSvg extends React.Component {
	render() {
		return (
			<svg 
				className={this.props.className ? this.props.className : ''}
				
				fill={this.props.color ? this.props.color : '#000'} 
				xmlns="http://www.w3.org/2000/svg" 
				width={this.props.size ? this.props.size : '80px'} 
				height={this.props.size ? this.props.size : '80px'} 
				viewBox="0 0 575.538 575.537" 
				stroke={this.props.color ? this.props.color : '#000'}
				>
					<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier"> <g> <path d="m248.833,66.166h-248.833v166h248.833l49.5-83-49.5-83zm-176.928,107.198c-3.766,2.73-8.756,4.098-14.978,4.098-6.245,0-11.648-1.57-16.207-4.703-4.558-3.135-6.774-7.561-6.648-13.453l.075-.139h12.43c0,3 0.855,5.35 2.566,6.711 1.715,1.361 4.308,1.996 7.784,1.996 2.645,0 4.615-0.552 5.912-1.608 1.296-1.059 1.946-2.456 1.946-4.169 0-1.938-0.644-3.441-1.927-4.498-1.285-1.059-3.59-2.183-6.914-3.366-6.95-2.191-12.101-4.54-15.451-7.048-3.35-2.506-5.023-6.126-5.023-10.86 0-4.609 1.995-8.339 5.988-11.185 3.99-2.846 9.059-4.268 15.205-4.268 6.094,0 11.086,1.568 14.979,4.703 3.891,3.135 5.772,7.3 5.648,12.237l-.077,.355h-12.391c0-2-0.742-4.101-2.229-5.474-1.485-1.371-3.538-2.124-6.156-2.124-2.57,0-4.587,0.532-6.045,1.669-1.46,1.131-2.191,2.514-2.191,4.176 0,1.615 0.737,2.893 2.208,3.864 1.474,0.971 4.237,2.182 8.293,3.643 6.245,1.863 10.949,4.152 14.111,6.873 3.158,2.719 4.738,6.458 4.738,11.216 0.002,4.837-1.877,8.619-5.646,11.354zm44.901,3.803l-3.137-11h-17.339l-3.1,11h-12.843l17.756-56h13.713l17.793,56h-12.843zm53.194,0h-37v-56h13v46h24v10zm45-46h-25v12h21v10h-21v14h25v10h-38v-56h38v10zm41.583,26.25c-4.557,0-8.25-3.693-8.25-8.25 0-4.557 3.693-8.25 8.25-8.25 4.557,0 8.25,3.693 8.25,8.25 0,4.556-3.693,8.25-8.25,8.25z"/> <polygon points="104.867,136.167 99.162,156.167 110.836,156.167 105.093,136.167 "/> </g> </g>

					</svg>
		)
	}
}

export default SaleSvg;