import React, { useEffect, useState, useCallback, Fragment, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Link,
} from "react-router-dom";
import './Header.css';
import i18n from '../i18n';
import LanguageSelect from '../language-select/LanguageSelect';
import SearchWindow from '../search-window/SearchWindow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeadset, faSearch, faUserCircle, faBars, faShoppingBasket, faTimes, faSignInAlt, faSignOutAlt, faJedi, faCaretRight, faCaretLeft, faSpinner, faDotCircle as faDotCircleSolid, faGift } from '@fortawesome/free-solid-svg-icons'
import { faClock, faHeart, faUserCircle as faUserCircleO, faDotCircle, } from '@fortawesome/free-regular-svg-icons'
import { faFacebook, } from '@fortawesome/free-brands-svg-icons'
import request from '../request';
import storage from '../storage';
import logoImage from '../assets/img/logo.png';
import Discounts from '../discounts/Discounts';
import discountBasket from '../discountBasket';
import ReactTooltip from "react-tooltip";

function Header(props) {
  const userMenuRef = useRef(null);
  const [showBasketToast, setShowBasketToast] = useState(false);
  const [searchWindowOpen, setSearchWindowOpen] = useState(false);
  const [navMobileOpen, setNavMobileOpen] = useState(false);
  const [discountsWindowOpen, setDiscountsWindowOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [userMobileMenuOpen, setUserMobileMenuOpen] = useState(false);
  const [navigationMenuType, setNavigationMenuType] = useState(localStorage['navigation-menu-type'] || 'normal');
  const [categories, setCategories] = useState([]);
  const [fetchingCategories, setFetchingCategories] = useState(false);
  const [headerFixed, setHeaderFixed] = useState(localStorage['header-fixed'] || '0');
  const [categoriesTree, setCategoriesTree] = useState([])
  const [categoryTreeView, setCategoryTreeView] = useState(false)
  const [userEmail, setUserEmail] = useState(null)

  const elementRef = React.createRef();

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    const handleClickOutside = () => {
      setUserMenuOpen(false)
    };
    
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    if(categoryTreeView){
      document.querySelector("body").style.overflow = "hidden";
    }else{
      document.querySelector("body").style.overflow = "auto";
    }

    return () => {
      document.querySelector("body").style.overflow = "auto";
    }
  }, [categoryTreeView])

  useEffect(() => {
    
    const handleCloseCategories = (e) => {
      if(e.target == document.querySelector('.categories-tree-wrapper'))
        setCategoryTreeView(false)
    };
    let a = document.querySelector('.categories-tree-wrapper');
    if(a) {
      a.addEventListener('click', handleCloseCategories, true);
      return () => {
        a.removeEventListener('click', handleCloseCategories, true);
      };
    }
    
  });

  useEffect(() => {
    const discount_evt = discountBasket.onQtyChange(() => {
      setShowBasketToast(true)
      setTimeout(() => {
        setShowBasketToast(false)
      }, 20000)
    })

    return () => {
      discount_evt()
    }
  })

  useEffect(() => {
    const unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      forceUpdate();
    });

    
    
    let storage_categories_tree;
    setFetchingCategories(true);
    request.get(`api/categories`).then((response) => {
      let data = response.data;
     
      setCategories(data);
      
      setFetchingCategories(false);
      
      
      storage_categories_tree = storage.onChange(() => {
        if(storage.categories_tree && storage.categories_tree.length > 0) {
          storage.categories_tree[0].active = true;
        }
        setCategoriesTree(storage.categories_tree);
      })

      storage.set('categories', data);
      //setCategoriesTree(storage.categories_tree);
      
    });

    return () => {
      unlistenOnLanguageChange();
      storage.clearOnChange(storage_categories_tree)
      //window.removeEventListener('click', onWindowClick);
    };
  }, []);



  const location = useLocation();
  useEffect(() => {
    setNavMobileOpen(false);
  }, [location]);

  useEffect(() => {
    if (navMobileOpen) {
      const mobileNavBtnClosePos = elementRef.current.querySelector('.navbar-mobile .btn-close').getBoundingClientRect();
      elementRef.current.querySelector('.navbar-mobile .content').style.top = mobileNavBtnClosePos.top + mobileNavBtnClosePos.height + (window.innerHeight * 0.09) + 'px';
    }
  }, [navMobileOpen]);

  useEffect(() => {
    const storage_fn_key = storage.onChange(() => {
      if(storage.me && storage.me.company_name){
        setUserEmail(storage.me ? storage.me.company_name : null)
      }else{
        setUserEmail(storage.me ? storage.me.email : null)
      }
      
    })
    if(storage.me && storage.me.company_name){
      setUserEmail(storage.me ? storage.me.company_name : null)
    }else{
      setUserEmail(storage.me ? storage.me.email : null)
    }
    return () => {
      storage.clearOnChange(storage_fn_key)
    }

  }, [])

  function closeSearchWindow() {
    setSearchWindowOpen(false);
  }

  function openMobileNavMenu() {
    setNavMobileOpen(true);
  }

  function closeMobileNavMenu() {
    setNavMobileOpen(false);
  }

  function changeNavigationMenuType(type) {
    setNavigationMenuType(type);
    localStorage.setItem('navigation-menu-type', type);
  }

  function changeHeaderFixed(state) {
    setHeaderFixed(state);
    localStorage.setItem('header-fixed', state);
    props.onHeaderTypeChange(state === '1' ? 'sticky' : 'normal');
  }

  function logout() {
    request.get('api/logout').then((response) => {
      if (response.data.status === 'ok') {
        props.onLogout();
      }
    });
  }

  function setInitialActiveCategory() {
    const cat = JSON.parse(JSON.stringify(categoriesTree));
    cat[0].active = true;
    setCategoriesTree(cat)
  }

  function setActiveCategory(c) {
    const cat = JSON.parse(JSON.stringify(categoriesTree));
    cat.map(c1 => {
      if(c1.id == c.id){
        c1.active = true;
      }
      else{
        c1.active = false;
      }
      
      return c1;
    }); 
    setCategoriesTree(cat)
  }

  let isLoggedIn = props.user.authenticated && Object.keys(storage.me).length > 0;
  
  const signinOptions = () => {
    if (isLoggedIn) {
      return  (<React.Fragment>
        <Link to="/account">
          <FontAwesomeIcon icon={faUserCircleO} />
          &nbsp;
          {i18n.t('my_account')}
        </Link>
        <Link to="" onClick={logout}>
          <FontAwesomeIcon icon={faSignOutAlt} />
          &nbsp;
          {i18n.t('signout')}
        </Link>
      </React.Fragment>);      
    } else {
      return  (
        <React.Fragment>
        <Link to="/signin">
          <FontAwesomeIcon icon={faSignInAlt} />
          &nbsp;
          {i18n.t('signin')}
        </Link>
        <Link to="/signup">
          <FontAwesomeIcon icon={faJedi} />
          &nbsp;
          {i18n.t('signup')}
        </Link>             
      </React.Fragment>);

    }
  }

  return (
    <div
      className={`header ${headerFixed === '1' ? 'fixed' : ''}`}
      ref={elementRef}
    >
      <div className="topbar">
        <div className="info">
          {/* <div className="entry">
            <FontAwesomeIcon icon={faClock} />
            <span>{i18n.t('visit_time')}:</span>
            <span>{i18n.t('monday_to_friday_and_hours')}</span>
          </div>
          <div className="entry">
            <FontAwesomeIcon icon={faHeadset} />
            <span>{i18n.t('call_us')}:</span>
            <span>{storage.company.phone1}</span>
          </div> */}
        </div>
        <div className="options">
        <div className="user"
        onClick={(e) => {
          e.stopPropagation()
          setUserMenuOpen(!userMenuOpen)
        }}>
            <div style={{display: "inline-block"}} ref={userMenuRef}>
              <FontAwesomeIcon
                icon={faUserCircle}
                size="lg"
                style={{color: "#b5b5b5"}}
                
              />
            </div>
            <span style={{marginLeft: "4px", fontSize: "12px"}}>{isLoggedIn ? userEmail : i18n.t('signin')}</span>
            <div 
              className={`options ${userMenuOpen ? 'open' : ''}`}
            >
              {signinOptions()}
            </div>
          </div>
          <LanguageSelect></LanguageSelect>
          <Link to="/contact">{i18n.t('contact')}</Link>
          
        </div>
      </div>
      <div className="navbar">
        <div className="logo">
          <Link to="/">
            <img src={logoImage} height="70" alt="Present team Logo" />
            <span className="d-none">Present team</span>
          </Link>
        </div>
        <div className="navigation">
                  <Link to="/">{i18n.t('home_page')}</Link>
                  <span onClick={() => setCategoryTreeView(!categoryTreeView)}>{i18n.t('products_navigation')}</span>
                  {
                    categoriesTree && categoriesTree.length > 1 ? 
                    <Link to="/categories">{i18n.t('categories')}</Link>
                    : categoriesTree && categoriesTree.length > 0 &&
                    <Link to={`/categories/${categoriesTree[0].id}`}>{i18n.t('categories')}</Link>
                  }
                  {
                    isLoggedIn &&
                    <Link to="/downloads">{i18n.t('download_items')}</Link>
                  }
                  <Link to="/blog">Blog</Link>
                  <Link to="/contact">{i18n.t('contact')}</Link>
                  <Link to="/basket">{i18n.t('basket')}</Link>
                  {/* <FontAwesomeIcon
                    icon={faCaretRight}
                    className="btn-swap-menu-to-categories"
                    onClick={() => changeNavigationMenuType('categories')}
                  /> */}
                  <div className={`categories-tree-wrapper ${categoryTreeView ? 'show' : ''}`}>
                    <div className={`categories-tree ${categoryTreeView ? 'show' : ''}`}>
                      {
                        categoriesTree && categoriesTree.length > 0 && 
                        <div className="child-category-picker">
                        {
                          categoriesTree.filter(a => a.children.length > 0).map((c, i)=> {
                            return <div key={`picker-${i}`} className={`title ${c.active ? 'active' : '' }`} onClick={() => setActiveCategory(c)}>{i18n.get_name(c)}</div>
                              {/* <div className='sub-categories'>
                                {
                                  c.children && c.children.length > 0 &&
                                  c.children.map(child => {
                                    return <Link to={`/categories/${child.id}`}>{i18n.get_name(child)}</Link>
                                  })
                                }
                              </div> */}
                            
                          })
                        }
                          <div className="close-category-tree" onClick={() => setCategoryTreeView(false)}>
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="btn-close"
                                
                            />
                          </div>
                          
                        </div>
                      }
                      {
                        categoriesTree && categoriesTree.length > 0 && 
                        <div className="child-category">
                        {
                          categoriesTree.map((c, i) => {
                            return <div key={`subcategories-${i}`} className={`sub-categories ${c.active ? 'active' : '' }`} >
                                    <h3><Link to={`/categories/${c.id}`} onClick={() => setCategoryTreeView(false)}>{i18n.get_name(c)}</Link></h3>
                                    <div className='categories-list'>
                                      {
                                        c.children && c.children.length > 0 &&
                                        c.children.map((child, i) => {
                                          if(child.children.length > 0){
                                            return <div key={`child-${i}`} className='third-level'>
                                              <div className='category-img'>
                                                {
                                                  child.image != '' &&
                                                  <img src={child.mini_image && child.mini_image != '' ? child.mini_image : child.image} />
                                                }
                                                <Link to={`/categories/${child.id}`} style={{fontWeight: 600}} onClick={() => setCategoryTreeView(false)}>{i18n.get_name(child)}</Link>
                                              </div>
                                              {
                                                child.children.map((sub, i) => {
                                                  return <Link key={`sub-${i}`} to={`/categories/${sub.id}`} style={{fontWeight: 400}} onClick={() => setCategoryTreeView(false)}>{i18n.get_name(sub)}</Link>
                                                })
                                              }
                                            </div>
                                            
                                            
                                          }else{
                                            return <div key={`child-${i}`} className='third-level'>
                                              <div className='category-img'>
                                                {
                                                  child.image != '' &&
                                                  <img src={child.mini_image && child.mini_image != '' ? child.mini_image : child.image} />
                                                }
                                                <Link to={`/categories/${child.id}`} style={{fontWeight: 600}} onClick={() => setCategoryTreeView(false)}>{i18n.get_name(child)}</Link></div>
                                              </div>
                                          }
                                          
                                        })
                                      }
                                    </div>
                                    {/* <div className='category-img'>
                                      {
                                        c.image != '' &&
                                        <img src={c.image} />
                                      }
                                    </div> */}
                              </div>
                            
                          })
                        }
                        </div>
                      }
                      {/* {
                        categoriesTree && categoriesTree.length > 0 &&
                        <div className="child-category">
                          <div className='sub-categories'>
                        {
                          categoriesTree[0].children.filter(a => a.children.length == 0).map(c => {
                            return <div className='title no-children'>
                                <Link to={`/categories/${c.id}`}>{i18n.get_name(c)}</Link>
                              </div>
                            
                          })
                        }
                          </div>
                        </div>
                      } */}
                      
                    </div>
                  </div>
        </div>
        <div className="controls">
          <div className="search" onClick={() => setSearchWindowOpen(true)}>
            <FontAwesomeIcon
              icon={faSearch}
              size="lg"
              data-tip data-for={`controlsTip-search`}
            />
            <ReactTooltip id={`controlsTip-search`} place="bottom" effect="solid" type="info">
              {i18n.t("search")}
            </ReactTooltip>
          </div>
          <div className="discounts" onClick={() => setDiscountsWindowOpen(true)}>
              <FontAwesomeIcon
                icon={faGift}
                size="lg"
                data-tip data-for={`controlsTip-discounts`}
              />
              <ReactTooltip id={`controlsTip-discounts`} place="bottom" effect="solid" type="info">
                {i18n.t("discounts_and_gifts")}
              </ReactTooltip>
            </div>
          {/* <div className="user">
            <FontAwesomeIcon
              icon={faUserCircle}
              size="lg"
              ref={userMenuRef}
              onClick={(e) => {
                e.stopPropagation()
                setUserMenuOpen(!userMenuOpen)
              }}
            />
            <div 
              className={`options ${userMenuOpen ? 'open' : ''}`}
            >
              {signinOptions()}
            </div>
          </div> */}
          <div className="favourite">
            <Link to="/products">
              <FontAwesomeIcon
                icon={faHeart}    
                size="lg"  
                data-tip data-for={`controlsTip-favs`}       
              />
              <ReactTooltip id={`controlsTip-favs`} place="bottom" effect="solid" type="info">
                {i18n.t("favorites")}
              </ReactTooltip>
            </Link>            
          </div>
          <div className="basket"  onClick={props.onOpenBasket}>
            <FontAwesomeIcon
              icon={faShoppingBasket}
              size="lg"
              data-tip data-for={`controlsTip-basket`}       
            />
            <ReactTooltip id={`controlsTip-basket`} place="bottom" effect="solid" type="info">
              {i18n.t("basket")}
            </ReactTooltip>
          </div>
        </div>
        <div className="mobile-controls">
          <div className="btn-mobile-navigation" onClick={openMobileNavMenu}>
            <FontAwesomeIcon
              icon={faBars}
            />
          </div>
        </div>
      </div>
      <div className={`navbar-mobile ${!navMobileOpen ? 'hidden' : ''}`} onClick={closeMobileNavMenu}>
        <FontAwesomeIcon
          icon={faTimes}
          className="btn-close"
        />
        <div className="content">
          <div className="controls">
            <div className="search" onClick={() => setSearchWindowOpen(true)}>
              <FontAwesomeIcon
                icon={faSearch}
                size="2x"
              />
            </div>
            <div className="discounts" onClick={() => setDiscountsWindowOpen(true)}>
              <FontAwesomeIcon
                icon={faGift}
                size="2x"
              />
            </div>
            <div className="user">
              <FontAwesomeIcon
                icon={faUserCircle}
                size="2x"
                style={{color: "#b5b5b5"}}
              />
              <div 
                className={`options ${userMobileMenuOpen ? 'open' : ''}`}
              >
                {
                  props.user.authenticated === false
                    ? <Link to="/signin">
                        <FontAwesomeIcon icon={faSignInAlt} />
                        &nbsp;
                        {i18n.t('signin')}
                      </Link>
                    : ''
                }
                {
                  props.user.authenticated === false
                    ? <Link to="/signup">
                        <FontAwesomeIcon icon={faJedi} />
                        &nbsp;
                        {i18n.t('signup')}
                      </Link>
                    : ''
                }
                {
                  props.user.authenticated
                    ? <Link to="">
                        <FontAwesomeIcon icon={faUserCircleO} />
                        &nbsp;
                        {i18n.t('my_account')}
                      </Link>
                    : ''
                }
                {
                  props.user.authenticated
                    ? <Link to="">
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        &nbsp;
                        {i18n.t('signout')}
                      </Link>
                    : ''
                }
              </div>
            </div>
            <div className="favorites">
              <Link to="/products">
                <FontAwesomeIcon
                  icon={faHeart}    
                  size="2x"         
                />
                <span className="d-none">Favourite Products</span>
              </Link>            
            </div>
            <div className="basket"  onClick={props.onOpenBasket}>
              <FontAwesomeIcon
                icon={faShoppingBasket}
                size="2x"
              />
            </div>
          </div>
          <div className="navigation">
            <Link to="/">{i18n.t('home_page')}</Link>
            <Link to="/products">{i18n.t('products_navigation')}</Link>
            {
              categoriesTree && categoriesTree.length > 1 ? 
              <Link to="/categories">{i18n.t('categories')}</Link>
              : categoriesTree && categoriesTree.length > 0 &&
              <Link to={`/categories/${categoriesTree[0].id}`}>{i18n.t('categories')}</Link>
            }
            <Link to="/blog">Blog</Link>
            <Link to="/contact">{i18n.t('contact')}</Link>
            <Link to="/basket">{i18n.t('basket')}</Link>
          </div>
        </div>
      </div>
      <SearchWindow 
        open={searchWindowOpen}
        onClose={closeSearchWindow}
      />
      <Discounts 
        open={discountsWindowOpen}
        onClose={setDiscountsWindowOpen}
      />
      <div className={`toast toast-review-basket`} style={{display: showBasketToast ? 'block' : 'none'}}>
        <div onClick={ () => setShowBasketToast(false)}>
          <FontAwesomeIcon
            icon={faTimes}
            className="btn-close"
          />
        </div>
        {i18n.t('removed_discount_prompt')} <button className='btn btn-primary' onClick={() => {
          props.onCloseBasket()
          setShowBasketToast(false)
          setDiscountsWindowOpen(true)
        }}>{i18n.t("here")}</button>
      </div>
    </div>
  );
}

export default Header;
