import './BasketPage.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBasket, faQrcode } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import NumberPicker from '../number-picker/NumberPicker';
import request from '../request';
import basket from '../basket';
import discountBasket from '../discountBasket';
import storage from '../storage';
import mathUtils from '../mathUtils';

class BasketPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      invalidProducts: [],
      discount_products: [],
      coupon: '',
      discountPerc: 0,
    };

    this.onCouponChange = this.onCouponChange.bind(this);
    this.calculatePrice = this.calculatePrice.bind(this);

    this.couponChangeTimeout = null;
  }

  componentDidMount() {
    document.title = i18n.t('basket_page_title');
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });

    discountBasket.validateBasketStorage()
    this.basketUnmount = basket.onChange(() => {
      this.setState({
        ...this.state,
        discount_products: discountBasket.products,
        products: basket.products,
        invalidProducts: basket.invalidProducts,
      });
      this.forceUpdate();
    });

    this.discountBasketUnmount = discountBasket.onChange(() => {
      this.setState({
        ...this.state,
        discount_products: discountBasket.products,
        products: basket.products,
      });
      this.forceUpdate();
    });

    this.setState({
      ...this.state,
      products: basket.products,
      discount_products: discountBasket.products,
      invalidProducts: basket.invalidProducts,
    });
  }
  componentDidUpdate() {
    document.title = i18n.t('basket_page_title');
  }
  componentWillUnmount() {
    this.unlistenOnLanguageChange();
    this.basketUnmount();
    this.discountBasketUnmount();
  }

  onCouponChange(e) {
    this.setState({
      ...this.state,
      coupon: e.target.value,
    });

    clearTimeout(this.couponChangeTimeout);
    this.couponChangeTimeout = setTimeout(() => {
      request.get(`api/check-coupon`).then((response) => {
        console.warn('Unhandled response: ', response);
        this.setState({
          ...this.state,
          discountPerc: 10,
        });
      });
    }, 350);
  }

  removeProduct(product) {
    basket.removeProduct(product, product.variant_id);
  }

  removeDiscountProduct(product) {
    discountBasket.removeProduct(product, product.variant_id, product.discount);
  }

  calculatePrice(product) {
    
    let total_qty = this.state.products.map((prod) => {
      return prod.id === product.id ? prod.quantity : 0;
    }).reduce((p, c) => {
      return p + c;
    }, 0);
    let valid_prices = product.prices.filter( (p) => {
      return p.qty <= total_qty
    })
    if(valid_prices.length > 0){
      let single_price = valid_prices.reduce(function (p, v) {
        return ( p.qty > v.qty ? p : v );
      });
      return mathUtils.round(single_price.price);
    }else{
      return 0;
    }
    
  }

  calculateDiscountPrice(product) {
    let price = product.price - ( (product.discount.percentage / 100) * product.price );
    
    return mathUtils.round(price);
  }

  calculateWholeDiscountPrice(product) {
    return ( mathUtils.round(( (product.price * product.quantity) - ( (product.discount.percentage / 100) * (product.price * product.quantity) ) )) )
  }
  calculateWholePrice(product) {
    let price = 0;
    let total_qty = this.state.products.map((prod) => {
      return prod.id === product.id ? prod.quantity : 0;
    }).reduce((p, c) => {
      return p + c;
    }, 0);
    let valid_prices = product.prices.filter( (p) => {
      return p.qty <= total_qty
    })
    if(valid_prices.length > 0){
      let single_price = valid_prices.reduce(function (p, v) {
        return ( p.qty > v.qty ? p : v );
      });
      return mathUtils.round(single_price.price * product.quantity);
    }else{
      return 0;
    }
    
  }

  getPicking(product){
    return product.picking_charge
  }

  calculateTotalProductPrice(product){
    
    return ( mathUtils.round(this.calculatePrice(product) * product.quantity) + parseFloat(this.getPicking(product)) )
  }

  calculatePickingPrice(){
    
    let price = 0;
    this.state.products.map( (product) => {
        price += mathUtils.round(this.getPicking(product))
          
        
      
    } )
    
    return price
  }

  

  getSubtotal(subtotal, subtotal_discount) {
    return (mathUtils.round(subtotal + subtotal_discount))
  }
  calcSubtotal(){
    var prods = [];
    if(this.state.products == null || this.state.products.length == 0) return 0;
    this.state.products.map(p => {
      var qty = 0;
      var price = this.calculatePrice(p);
      if(typeof prods[p.id] != 'undefined'){
        qty = prods[p.id].qty;
      }
      qty += p.quantity;
      prods[p.id] = {
        qty: qty,
        price: price
      }
    })
    var total = 0;
    var prices = prods.map(p => p.price * p.qty);
    if(prices.length > 0){
      total = prices.reduce((a, b) => a + b);
    }
    return mathUtils.round(total);
  }

  getGrandTotal() {
    const vat = storage.me.vat ? (mathUtils.round(storage.me.vat) / 100) : 0.24;
    var prods = [];
    if(this.state.products == null || this.state.products.length == 0) return 0;
    this.state.products.map(p => {
      var qty = 0;
      var price = this.calculatePrice(p);
      var picking = 0;
      if(typeof prods[p.id] != 'undefined'){
        qty = prods[p.id].qty;
        picking = prods[p.id].picking;
      }
      qty += p.quantity;
      picking += mathUtils.round(this.getPicking(p));
      prods[p.id] = {
        qty: qty,
        price: price,
        picking: picking
      }
    })
    var total = 0;
    var prices = prods.map(p => mathUtils.round((p.price * p.qty + p.picking) * (vat + 1)));
    if(prices.length > 0){
      total = prices.reduce((a, b) => a + b);
    }
    return mathUtils.round(total);
  }

  getDiscountSubtotal(){
    var prods = [];
    if(this.state.discount_products == null || this.state.discount_products.length == 0) return 0;
    this.state.discount_products.map(p => {
      var qty = 0;
      var price = p.price;
      if(typeof prods[`line${p.id}_${p.discount.id}`] != 'undefined'){
        qty = prods[`line${p.id}_${p.discount.id}`].qty;
      }
      qty += p.quantity;
      prods[`line${p.id}_${p.discount.id}`] = {
        qty: qty,
        price: price,
      }
    })
    
    var total = 0;
    var index_array = [];
    for (var key in prods) {
      index_array.push({
        qty: prods[key].qty,
        price: prods[key].price,
      })
    }
    var prices = index_array.map(p1 => {
      return p1.price * p1.qty;
    });
    if(prices.length > 0){
      total = prices.reduce((a, b) => a + b, 0);
    }
    return mathUtils.round(total);
  }

  getDiscountPrice(vat){
    
    var prods = [];
    if(this.state.discount_products == null || this.state.discount_products.length == 0) return 0;
    this.state.discount_products.map(p => {
      var qty = 0;
      var price = p.price;
      if(typeof prods[`${p.id}-${p.discount.id}`] != 'undefined'){
        qty = prods[`${p.id}-${p.discount.id}`].qty;
      }
      qty += p.quantity;
      prods[`${p.id}-${p.discount.id}`] = {
        qty: qty,
        price: price,
        discount: p.discount.percentage
      }
    })
    //console.log(prods)
    var total = 0;
    var index_array = [];
    for (var key in prods) {
      index_array.push({
        qty: prods[key].qty,
        price: prods[key].price,
        discount: prods[key].discount,
      })
    }
    var prices = index_array.map(p => mathUtils.round( ((p.price * p.qty) - ((p.price * p.qty) * (p.discount / 100))) * (vat + 1), 3) );
    if(prices.length > 0){
      total = prices.reduce((a, b) => a + b);
    }
    return mathUtils.round(total);
  }

  render() {
    const vat = storage.me.vat ? (mathUtils.round(storage.me.vat) / 100) : 0.24;
    const subtotal = this.calcSubtotal();
    
    const subtotal_after_discount = this.getDiscountPrice(vat)

    const subtotal_after_discount_clear = this.getDiscountPrice(0)

    const subtotal_discount = this.getDiscountSubtotal()


    //console.log(subtotal, subtotal_discount)
    
    const discount = subtotal * (this.state.discountPerc / 100);
    
    //const grandTotal = subtotal + taxes;
    const picking = (this.calculatePickingPrice());
    const grandTotal = this.getGrandTotal()

    const grandTotal_discount = subtotal_after_discount

    const taxes =  ((grandTotal + grandTotal_discount) - (subtotal + subtotal_after_discount_clear + picking) );

    return (
      <div className={`basket-page ${this.state.products.length === 0 && localStorage.basket === '{}' ? 'd-row-center empty-height' : ''}`}>
        {
          this.state.products.length === 0 && localStorage.basket !== '{}'
            ? <div className="skeleton"></div>
            : ''
        }
        {
          this.state.products.length === 0 && localStorage.basket === '{}'
            ? <div className="d-column-center h-100">
                <FontAwesomeIcon icon={faShoppingBasket} size="3x" />
                <span className="mt-2">{i18n.t('empty_basket')}</span>
              </div>
            : ''
        }
        <table className={`${this.state.products.length === 0 ? 'hidden' : ''}`}>
          <thead>
            <tr>
              <th>{i18n.t('image')}</th>
              <th>{i18n.t('product_name')}</th>
              <th>{i18n.t('unit_price')}</th>
              <th>{i18n.t('quantity')}</th>
              <th>{i18n.t('subtotal')}</th>
              <th>{i18n.t('picking_charge')}</th>
              <th>{i18n.t('action')}</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.products.length === 0
                ? <tr><td colSpan="6">{i18n.t('empty_basket')}</td></tr>
                : <tr></tr>
            }
            {
              this.state.products.map((product, i) => {
                return (
                  <tr
                    key={i}
                  >
                    <td style={{textAlign: "center"}}><img src={product.thumbnail} height="60" alt="" /></td>
                    <td className="name"><Link to={`/products/${product.id}`}>{i18n.get_name(product)}</Link></td>
                    <td>{this.calculatePrice(product)}&nbsp;€</td>
                    <td>
                      <div className="d-row-center">
                        <span>{product.quantity}
                        </span>
                      </div>
                    </td>
                    <td>{parseFloat((this.calculatePrice(product)) * product.quantity).toFixed(2)}&nbsp;€</td>
                    <td>{parseFloat((this.getPicking(product))).toFixed(2)}&nbsp;€</td>
                    <td>
                      <div className="d-row-center">
                        <button
                          type="button"
                          className="btn btn-sm btn-outline"
                          onClick={() => this.removeProduct(product)}
                        >
                          {i18n.t('remove_product_from_basket')}
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            }
            {
              this.state.discount_products &&
              this.state.discount_products.map((product, i) => {
                return (
                  <tr
                    key={i}
                  >
                    <td style={{textAlign: "center"}}><img src={product.thumbnail} height="60" alt="" /></td>
                    <td className="name"><Link to={`/products/${product.id}`}>{product.name}</Link></td>
                    <td>
                        <span className='from-price'>Από {parseFloat(product.price).toFixed(2)}€</span> &nbsp;
                        <span className='to-price'>
                        {this.calculateDiscountPrice(product) > 0 ? this.calculateDiscountPrice(product).toFixed(2) + ' €' : i18n.t("gift")}
                        </span>
                    </td>
                    <td>
                      <div className="d-row-center">
                        <span>{product.quantity}
                        </span>
                      </div>
                    </td>
                    <td><span className='to-price'>{this.calculateWholeDiscountPrice(product) > 0 ? this.calculateWholeDiscountPrice(product).toFixed(2) + " €" : i18n.t("gift")}</span></td>
                    <td>0.00&nbsp;€</td>
                    <td>
                      <div className="d-row-center">
                        <button
                          type="button"
                          className="btn btn-sm btn-outline"
                          onClick={() => this.removeDiscountProduct(product)}
                        >
                          {i18n.t('remove_product_from_basket')}
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        {
          this.state.invalidProducts && this.state.invalidProducts.length > 0 &&
          <div>
            <h4>{i18n.t("qty_changed_since_last_time_basket_prompt")}</h4>
            <table className={`${this.state.products.length === 0 ? 'hidden' : ''}`}>
              <thead>
                <tr>
                  <th>{i18n.t('image')}</th>
                  <th>{i18n.t('product_name')}</th>
                  <th>{i18n.t('unit_price')}</th>
                  <th>{i18n.t('quantity')}</th>
                  <th>{i18n.t('subtotal')}</th>
                  <th>{i18n.t('picking_charge')}</th>
                  <th>{i18n.t('action')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.invalidProducts.map((product, i) => {
                    return (
                      <tr
                        key={i}
                      >
                        <td style={{textAlign: "center"}}><img src={product.thumbnail} height="60" alt="" /></td>
                        <td className="name"><Link to={`/products/${product.id}`}>{i18n.get_name(product)}</Link></td>
                        <td></td>
                        <td>
                          <div className="d-row-center">
                            <span>{product.quantity}
                            </span>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })
                }
                <tr>
                  <td colSpan="7">
                    <button className="btn-clear-invalid" style={{float: 'right'}} onClick={() => basket.removeInvalidProducts()}>{i18n.t('clear')}</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          
        }
        <div className={`total mt-2 ${this.state.products.length === 0 ? 'hidden' : ''}`}>
          <div className="header">
            {i18n.t('order_total')}
          </div>
          <div className="body">
            <div>
              <span>{i18n.t('subtotal')}</span>
              <span>{this.getSubtotal(subtotal, subtotal_discount).toFixed(2)}&nbsp;€</span>
            </div>
            {
              subtotal_discount > 0 
              &&
              <div>
                <span>{i18n.t('discount')}</span>
                <span>{mathUtils.round(this.getSubtotal(subtotal, subtotal_discount) - this.getSubtotal(subtotal, subtotal_after_discount_clear) ).toFixed(2)}&nbsp;€</span>
              </div>
            }
            {
              picking > 0 
              &&
              <div>
                <span>{i18n.t('picking_charge')}</span>
                <span>{parseFloat(picking).toFixed(2)}&nbsp;€</span>
              </div>
            }
            <div>
              <span>{i18n.t('taxes')}</span>
              <span>{mathUtils.round(taxes).toFixed(2)}&nbsp;€</span>
            </div>
          </div>
          <div className="coupon">
            <FontAwesomeIcon icon={faQrcode} />
            <label htmlFor="coupon">Coupon</label>
            <input
              id="coupon"
              type="text"
              placeholder={i18n.t('input_coupon')}
              value={this.state.coupon}
              onChange={this.onCouponChange}
            />
          </div>
          <div className="footer">
            <span>{i18n.t('grand_total')}</span>
            <span>{(discount ? parseFloat(grandTotal + subtotal_after_discount - discount).toFixed(2) : parseFloat(grandTotal + subtotal_after_discount).toFixed(2))}&nbsp;€</span>
          </div>
          <div className="controls">
            <Link
              to="/"
              className="btn btn-outline mb-2 ml-2"
            >
              {i18n.t('continue_shopping')}
            </Link>
            <Link
              to="/checkout"
              className="btn mb-2 mr-2"
            >
              {i18n.t('continue_to_checkout')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default BasketPage;